import React from 'react';
import { Dialog, useTheme, Avatar, Card, CardContent, CardHeader, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import useStyles from './style';
import { CalendarEditIcon, EnvelopeOpenTextIcon, FileIcon, FilePdfIcon, IdCardIcon, MapMarkerIcon, PhoneIcon, TruckLoadingIcon, WhatsappIcon } from '../../helpers/icons';
import { ProveedorArchivosDialog } from '..';

const Page = ({open, handleClose, proveedor, handleArchivosClose, handleArchivosOpen, handleUpdate}) => {
  const classes = useStyles();
  const theme = useTheme();
  const pColor = theme.palette.primary.main;
  const sColor = theme.palette.secondary.main;

  return (
      <Dialog onClose={handleClose} open={open}>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {proveedor.nombre[0]}
              </Avatar>
            }
            title={proveedor.nombre}
            subheader={proveedor.rubro}
          />
          <CardContent>
            <List component="nav" aria-label="main mailbox folders">
              <ProveedorItem dato={proveedor.contacto} icon={<IdCardIcon primaryColor={pColor} secondaryColor={sColor} width={25} height={25}  />} />
              <ProveedorItem dato={proveedor.telefono} icon={<PhoneIcon primaryColor={pColor} secondaryColor={sColor} width={25} height={25}  />} />
              <ProveedorItem dato={proveedor.celular} icon={<WhatsappIcon primaryColor={sColor} secondaryColor={sColor} width={25} height={25}  />} />
              <ProveedorItem dato={proveedor.email}  icon={<EnvelopeOpenTextIcon primaryColor={pColor} secondaryColor={sColor} width={25} height={25}  />} />
              <ProveedorItem dato={proveedor.direccion} icon={<MapMarkerIcon primaryColor={pColor} secondaryColor={sColor} width={25} height={25}  />} />
              <ProveedorItem dato={proveedor.pedidos}  icon={<CalendarEditIcon primaryColor={pColor} secondaryColor={sColor} width={25} height={25}  />} />
              <ProveedorItem dato={proveedor.entregas} icon={<TruckLoadingIcon primaryColor={pColor} secondaryColor={sColor} width={25} height={25}  />} />
              <ProveedorItem dato={proveedor.comprobanteStr} icon={<FileIcon primaryColor={pColor} secondaryColor={sColor} width={25} height={25} />} />
              <ProveedorItem button onClick={handleArchivosOpen} dato={"Archivos"} icon={<FilePdfIcon primaryColor={pColor} secondaryColor={sColor} width={25} height={25} />} />
            </List>
          </CardContent>
        </Card>
        <ProveedorArchivosDialog handleClose={handleArchivosClose} handleUpdate={handleUpdate}/>
      </Dialog>
      
  );
};


const ProveedorItem = ({dato, icon, onClick, button}) => {
  return (
    <>
      {dato && 
        <ListItem button={button} onClick={onClick}>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          <ListItemText primary={dato} />
        </ListItem>    
      }
    </>
  )
}

export default Page;