
import { authConstants } from "../constants";

const defaultState = {
  remember: false,
  isAuthenticated: false, 
  name: "", 
  userName: "", 
  role: "", 
  loaded: false, 
  isFetching: false, 
  userId: 0,
};

export const reducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case authConstants.RELOAD_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        name: payload.name, 
        userName: payload.userName, 
        role: payload.role, 
        remember: payload.remember,
        isFetching: false,
        loaded: true,
      };
    case authConstants.RELOAD_FAILURE:
      return {
        ...defaultState,
        loaded: true
      };      
    case authConstants.RELOAD_EMPTY_TOKEN:
      return {
        ...defaultState,
        loaded: true
      };  
    case authConstants.LOGIN_REQUEST: 
      return {
        ...state,
        isFetching: true,
      };
    case authConstants.LOGIN_SUCCESS: 
    console.log(payload)
      return {
        ...state,
        isAuthenticated: true,
        name: payload.name, 
        userName: payload.userName, 
        role: payload.role, 
        isFetching: false,
        remember: payload.remember,
        userId: payload.id,
      }
    case authConstants.LOGIN_FAILURE: 
      return {
        ...state,
        isFetching: false,
      }
  
    case authConstants.LOGOUT: 
      return {
        ...defaultState,
        loaded: true,
      };
      
    default:
      return state;
  }
}
