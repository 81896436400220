import axios from 'axios';
import { IdCardIcon } from '../helpers/icons';
import sendAsync from '../_core/MessageControlRendered';
//export const API_URL = "https://localhost:6001/api"; 
export const API_URL = "http://santaanitaapi.pablogonzalez.ar/api"; 
export const useApiAuth = () => {
  
  const login = (usuario, password) => {
    
    let user;
    return new Promise(function(resolve, reject) {
      
      if(usuario === "admin" && password === "123sa"){
        resolve({
          name: usuario, 
          userName: usuario, 
          role: "Admin", 
          remember: false,
        });
      }

      axios.post(`${API_URL}/vendedores/Login`, {usuario, password})
      .then((response) => {
        resolve({
          name: usuario, 
          userName: usuario, 
          role: usuario.toLowerCase() === "admin" ? "Admin" : "Vendedor", 
          remember: false,
          id: response.data.id,
        });
      })
      .catch(()=>{
        reject("Error de login");
      });

    });

  }

  const reloadToken = (token) => {
    return new Promise(function(resolve, reject) {
      if(token.remember){
        resolve(token);
      }else{
        reject("No recuerda login");
      }
    });
  }

  return {login, reloadToken};

}
/*
export const ApiBase = (table) => {

  const index = (token) => {
    return sendAsync(table + "-get");
  }
      
  const getItem = (id, token) => {
    return sendAsync(table + "-get-item", id);
  }
  
  const editForm = (id, token) => {
    return sendAsync(table + "-get-form", id);
  }
  
  const editItem = (id, data, token) => {
    return sendAsync(table + "-edit-item", data);
  }
  
  const addForm = (token) => {
    return sendAsync(table + "-get-form");
  }
  
  const addItem = (data, token) => {
    return sendAsync(table + "-add-item", data);
  }
  
  const remove = (id, token) => {
  }
  
  return {index, getItem, addItem, editItem, editForm, addForm};
}
*/


export const ApiBase = (controller) => {

  const index = (token) => {
    return axios.get(`${API_URL}/${controller}`, {headers : {Authorization : "Bearer " + token}});
  }
  
  const editForm = (id, token) => {
    return axios.get(`${API_URL}/${controller}/EditForm/${id}`, {headers : {Authorization : "Bearer " + token}});
  }
  
  const editItem = (id, data, token) => {
    return axios.put(`${API_URL}/${controller}/${id}`, data, {headers : {Authorization : "Bearer " + token}});
  }
  
  const addForm = (token) => {
    return axios.get(`${API_URL}/${controller}/CreateForm`, {headers : {Authorization : "Bearer " + token}});
  }
  
  const addItem = (data, token) => {
    return axios.post(`${API_URL}/${controller}`, data, {headers : {Authorization : "Bearer " + token}});
  }
  
  const removeItem = (id, token) => {
    return axios.delete(`${API_URL}/${controller}/${id}`, {headers : {Authorization : "Bearer " + token}});
  }
  
  return {index, editForm, editItem, addForm, addItem, removeItem};
}
export const useApiProductos = () => ApiBase("productos");
export const useApiProveedores = () => {
  const controller = "proveedores";
  const pedidos = (token) => {
    return axios.get(`${API_URL}/${controller}/Pedidos`, {headers : {Authorization : "Bearer " + token}});
  }
  const entregas = (token) => {
    return axios.get(`${API_URL}/${controller}/Entregas`, {headers : {Authorization : "Bearer " + token}});
  }
  const uploadFile = (id, data, token) => {
    return axios.post(`${API_URL}/${controller}/UploadFile/${id}`, data, {headers : {Authorization : "Bearer " + token}});
  }
  const removeFile = (id, token) => {
    return axios.delete(`${API_URL}/${controller}/RemoveFile/${id}`, {headers : {Authorization : "Bearer " + token}});
  }
  return {...ApiBase(controller), pedidos, entregas, uploadFile, removeFile}
};
export const useApiVentas = () => {
  const controller = "ventas";
  const basicIndex = (token) => {
    return axios.get(`${API_URL}/${controller}/Basic`, {headers : {Authorization : "Bearer " + token}});
  }
  return {...ApiBase(controller), basicIndex}
};
export const useApiVendedores = () => ApiBase("vendedores");
export const useApiMediosPago = () => ApiBase("mediosPago");

export const useApiReportes = () => {
  const controller = "reportes";
  const reporteVentasDiarias = (values, token) => {
    return axios.post(`${API_URL}/${controller}/VentasDiarias`, values ,{headers : {Authorization : "Bearer " + token}});
  }
  return {reporteVentasDiarias}
}