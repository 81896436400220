import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  separador: {
    flexGrow: 1,
  },
  popoverTypography: {
    padding: theme.spacing(2),
  },
  noEstasRegistrado: {
    margin: "6px",
    padding: "6px",
    textAlign: "center"
  },
  ingresar: {
    textAlign: "center"
  },
  registrate: {
    
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  logo: {
    height: "50px",
    paddingTop: "10px",
    paddingRight: "20px",
  },
  buscarPropiedades: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(4),
    //borderRadius: theme.shape.borderRadius,
    //backgroundColor: fade(theme.palette.common.white, 0.15),
    //'&:hover': {
    //  backgroundColor: fade(theme.palette.common.white, 0.25),
    //},
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
      width: 'auto',
    },
  },

  publicar: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  noticias:{
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'unset',
      width: 'auto',
    },
  },
  botonMiCuenta:{
    textAlign: "left"
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1)
  },
}));

export default useStyles;