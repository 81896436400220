import { combineReducers } from "redux";
import { reducer as auth } from "./auth.reducer";
import { reducer as appBar } from "./appBar.reducer";
import { reducer as productos } from "./productos.reducer";
import { reducer as proveedores } from "./proveedores.reducer";
import { reducer as ventas } from "./ventas.reducer";
import { reducer as vendedores } from "./vendedores.reducer";
import { reducer as mediosPago } from "./mediosPago.reducer";
import { reducer as reportes } from "./reportes.reducer";

const rootReducer = combineReducers({
  auth,
  appBar,
  productos,
  proveedores,
  ventas,
  vendedores,
  mediosPago,
  reportes,
})

export default rootReducer;