import React from 'react';
import useStyles from './style';
import { FormVenta } from '../../components';


const Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormVenta title="Editar Venta" subTitle="complete el formulario para editar la venta, confirme las modificaciones con el botón guardar"/>
    </div>
  );
};

export default Page;