import React, { useEffect } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { reportesActions } from '../../redux/actions';
import * as yup from 'yup';
import moment from "moment";
import { useFormik } from 'formik';
import { useSnackContext } from '../../context/Snack';

export const validationSchema = yup.object({
  desde: yup
    .date()
    .required('La fecha es requerida'),
  hasta: yup
    .date()
    .required('La fecha es requerida'),
});

const _Index = (props) => {

  const { openAxiosError } = useSnackContext();

  const rows = [
    ...props.reporteVentasDiarias.reporte.map(x => ({...x, fecha: moment(x.fecha).toDate()})),
  ]

  const columns =  [
    { field: 'fecha', headerName: 'Fecha', flex: 1, type: 'date' },
    { field: 'medioPago', headerName: 'Medio de pago', flex: 1 },
    { field: 'vendedores', headerName: 'Vendedores', flex: 1 },
    { field: 'total', headerName: 'Total', flex: 1},
  ];

  const footerRows = [
    ...props.reporteVentasDiarias.totalizado.map((x,index)=>{
      return {
        id: 999999998 +  index,
        a: '', 
        b: '',
        medioPago: x.medioPago, 
        total: x.total
      }          
    }),
    ...[{
      id: 999999996,
      a: '', 
      b: '',
      medioPago: '', 
      total: props.reporteVentasDiarias.total
    }],

  ]
  const footerColumns =  [
    { field: 'a', headerName: '.', flex: 1 },
    { field: 'b', headerName: '.', flex: 1 },
    { field: 'medioPago', headerName: 'Medio de pago', flex: 1 },
    { field: 'total', headerName: 'Total', flex: 1},
  ];

  const formik = useFormik({
    initialValues: {
      desde: moment().add(-1, 'months'),
      hasta: moment(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.setSubmitting(true);
      props.index(values)
      .then( (response) => {
        formik.setSubmitting(false);
      })
      .catch( (error) => {
        formik.setSubmitting(false);
        openAxiosError(error);
      })
    },
  });

  return <Page {...{columns, rows, formik, footerColumns, footerRows}}  />;
};

const mapStateToProps = state => ({
  reporteVentasDiarias: state.reportes.reporteVentasDiarias,
});

const mapDispatchToProps = {
  index: reportesActions.reporteVentasDiarias,
};

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
