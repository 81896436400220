import React, { useState } from 'react';
import useStyles from './style';
import { Button, Collapse, Grid, Link, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography } from '@material-ui/core';
import { AppBar } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { ListItemLink } from '../../helpers';
import { ExpandLess, ExpandMore, StarBorder } from '@material-ui/icons';
import { WalletIcon } from '../../helpers/icons';
import { useTheme } from '@material-ui/core';

const Page = ({title, children, iconAdd, iconBack, isAdmin, isAuthenticated, showMenu}) => {
  const classes = useStyles();
  const theme = useTheme();
  const pColor = theme.palette.secondary.main;
  const sColor = theme.palette.primary.main;
  const [openReportes, setOpenReportes] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} color="secondary" position="sticky" >
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.title} noWrap>
            {title()}
          </Typography>
          {iconBack()}
          {iconAdd()}
        </Toolbar>
      </AppBar>
      <Grid container spacing={3}>
        <Grid item xs={12}/>

        {isAuthenticated && 
          <>
            {showMenu && 
              <Grid item xs={12} md={2} >
                <List
                  component="nav"
                  subheader={<h4 className={classes.subHeaderMenu}>MENU</h4>}
                >
                  <ListItemLink href="/Dashboard/Ventas">
                    <ListItemText primary="Ventas"  />
                  </ListItemLink>
                  <ListItemLink href="/Dashboard/Productos">
                    <ListItemText primary="Productos"  />
                  </ListItemLink>
                  <ListItemLink href="/Dashboard/Proveedores">
                    <ListItemText primary="Proveedores"  />
                  </ListItemLink>
                </List>

                {isAdmin && 
                  <List
                    component="nav"
                    subheader={<h4 className={classes.subHeaderMenu}>ADMIN</h4>}
                  >
                    <ListItemLink href="/Dashboard/Vendedores">
                      <ListItemText primary="Vendedores"  />
                    </ListItemLink>
                    <ListItemLink href="/Dashboard/MediosPago">
                      <ListItemText primary="Medios de Pago"  />
                    </ListItemLink>
                    <ListItem button onClick={()=>{setOpenReportes(!openReportes)}}>
                      <ListItemText primary="Reportes" />
                      {openReportes ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openReportes} timeout="auto" unmountOnExit className={classes.subMenu}>
                      <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                          <ListItemLink href="/Dashboard/ReporteVentasDiarias">
                            <ListItemIcon>
                              <WalletIcon primaryColor={pColor} secondaryColor={sColor} width={24} height={24} />
                            </ListItemIcon>
                            <ListItemText primary="Ventas Diarias"  />
                          </ListItemLink>
                        </ListItem>
                      </List>
                    </Collapse>
                  </List>
                }
              </Grid>    
            }      
            <Grid item xs={12} md={showMenu ? 10 : 12}>
              {children}
            </Grid>     
          </>    
        }

        <Grid item xs={12}/>
      </Grid>
    </div>
  );
};

export default Page;