import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  content:{
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
  },
  subMenu:{
    paddingLeft: theme.spacing(2),
  },
  subHeaderMenu: {
    padding: theme.spacing(1)
  }
}));

export default useStyles;