import React, { useEffect } from 'react';
import Page from './page';
import * as yup from 'yup';
import { vendedoresActions } from '../../../redux/actions';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { useSnackContext } from '../../../context/Snack';
import { useHistory, useParams } from 'react-router';

export const validationSchema = yup.object({
  id: yup
    .number(),
  usuario: yup
    .string()
    .required('El usuario es requerido')
    .max(45, 'Puede ingresar hasta 45 caracteres'),
  password: yup
    .string()
    .max(45, 'Puede ingresar hasta 45 caracteres'),
  activo: yup
    .bool(),
});

export const _Index = ({editForm, addForm, editItem, addItem, item, title, subTitle, handleControlChange}) => {
  let { id } = useParams()
  let history = useHistory();
  const { openAxiosError, openSuccess } = useSnackContext();

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    const fn = id ? () => editForm(id) : () => addForm();
    formik.setSubmitting(true);
    fn()
    .then(response => {
      formik.setSubmitting(false);
      response.item && formik.setValues(response.item);
    })
    .catch(error => {
      formik.setSubmitting(false);
      openAxiosError(error);
    })
  }
   
  const formik = useFormik({
    initialValues: {
      ...item,
      id: id ? id : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.setSubmitting(true);
      const fn = id ? () => editItem(id, values) : () => addItem(values);
      fn()
      .then( response => {
        const msg = id ? "El item se editó correctamente." : "El item se creó correctamente.";
        openSuccess(
          msg, 
          ()=>{ 
            history.replace("/Dashboard/Vendedores");
          }
        )
      })
      .catch( error => {
        formik.setSubmitting(false);
        openAxiosError(error);
      })
    },
  });

  const handleChange = (event) => {
    handleControlChange && handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
    formik.setFieldValue( event.target.name, event.target.value );
  }

  return (
    <Page {...{formik, title, subTitle, handleChange, item}}/>
  );
}

const mapStateToProps = state => ({
  item: state.vendedores.item,
});

const mapDispatchToProps = {
  addForm: vendedoresActions.addForm,
  editForm: vendedoresActions.editForm,
  addItem: vendedoresActions.addItem,
  editItem: vendedoresActions.editItem,
}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
