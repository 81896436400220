import React from 'react';
import useStyles from './style';
import { ContentWrapper, DataGridIndex, DatePicker } from '../../helpers';
import { Button, Grid } from '@material-ui/core';

const Page = ({ columns, rows, formik, footerColumns, footerRows }) => {
  const classes = useStyles();
  const footer = () => {
    return (
      <DataGridIndex hideFooter={true} rows={footerRows} columns={footerColumns} components={{Toolbar: undefined}} />
    );
  };

  return (
    <div className={classes.root}>

      <form autoComplete="off"  onSubmit={formik.handleSubmit}>
        <ContentWrapper title="Reporte de ventas diarias" subTitle="seleccione el rango de fechas que desea consultar" actions={
          <>
            <Button variant="contained" color="primary" type="submit"  disabled={formik.isSubmitting}>
              Consultar
            </Button>
          </>
        }>
          <Grid container>
            <Grid md="6" className={classes.formControl}>
              <DatePicker
                name= "desde"
                formik={formik}
                label="Desde"
                onChange={formik.handleChange}
                invalidDateMessage="La fecha es inválida"
                minDateMessage="La fecha es inválida"
                maxDateMessage="La fecha es inválida"                    
              />
            </Grid>
            <Grid md="6" className={classes.formControl}>
              <DatePicker
                name= "hasta"
                formik={formik}
                label="Hasta"
                onChange={formik.handleChange}
                invalidDateMessage="La fecha es inválida"
                minDateMessage="La fecha es inválida"
                maxDateMessage="La fecha es inválida"                    
              />
            </Grid>

          </Grid>
        </ContentWrapper>
      </form>
      <DataGridIndex hideFooter={false} rows={rows} columns={columns} components={{Footer: footer}} height={500}/>
    </div>
  );
};

export default Page;