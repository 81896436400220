import { FormControl, FormControlLabel, makeStyles, Switch } from "@material-ui/core"
import React from "react"


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


export const SwitchGiz = ({name, label, formik, title, readOnly = true, onChange=()=>{}}) => {

  const classes = useStyles();

  return (
    <FormControl fullWidth  variant="outlined" className={classes.formControl} >
      <FormControlLabel 
        control={
          <Switch
            title={title}
            variant="outlined"
            id={name}
            name={name}
            color="primary" 
            readOnly={readOnly}
            checked={formik.values[name]} 
            onChange={(event) => onChange({target:{value:event.target.checked, name: name}})}
            value={true}/>
        }
        label={label}
        labelPlacement="end"
      />
    </FormControl>
  )
}
