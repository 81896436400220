import React from 'react';
import useStyles from './style.js'

//import Autocomplete from '../autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { ListaEntregas, ListaPedidos, LoginDialog, Popover } from '../';
import { Button, Link, Typography, IconButton, Toolbar, AppBar, Avatar} from '@material-ui/core';

import logo from "../../assets/img/logo/logo-appbar.png";

import ListItemIcon from '@material-ui/core/ListItemIcon';

import { SignOutAltIcon } from '../../helpers/icons/';
import { useTheme } from '@material-ui/core/styles';
import { ListItemLink } from '../../helpers/ListItemLink.js';
import { Link as RouterLink } from 'react-router-dom';

const Page = ({
        auth,
        anchorMiCuenta,
        anchorPedidos,
        anchorEntregas,
        handleMiCuentaClose,
        handleMiCuentaOpen,
        handleLoginDialogOpen,
        handleLoginDialogClose,
        showLoginDialog,
        handleLogoutClick,
        handlePedidosOpen, 
        handlePedidosClose, 
        handleEntregasOpen, 
        handleEntregasClose,
        handleToggleMenu
    }) => {
      const classes = useStyles();
      const theme = useTheme();

      const pColor = theme.palette.primary.main;
      const sColor = theme.palette.secondary.main;
      
    return (
        <div className={classes.root}>
          <AppBar>
            <Toolbar>
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleToggleMenu}>
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.title} noWrap>
                <img className={classes.logo} src={logo} alt="Logo" />
              </Typography>
              <div className={classes.separador}></div>
              <Button color="inherit" id="pop-pedidos" onClick={handlePedidosOpen}>Pedidos</Button>
              <Button color="inherit" id="pop-entregas" onClick={handleEntregasOpen}>Entregas</Button>
              <Button color="inherit" id="pop-mi-cuenta" onClick={handleMiCuentaOpen}>
                {
                  auth && auth.photoURL &&
                  <Avatar className={classes.avatar} alt="foto" src={auth.photoURL} />
                }
                {auth && auth.name ? auth.name: "Mi Cuenta"}</Button>
            </Toolbar>
          </AppBar>

          <Popover anchorEl={anchorPedidos} handleClose={handlePedidosClose} id="pop-pedidos" >
              <ListaPedidos />
          </Popover>

          <Popover anchorEl={anchorEntregas} handleClose={handleEntregasClose} id="pop-entregas" >
            <ListaEntregas />
          </Popover>

          <Popover anchorEl={anchorMiCuenta} handleClose={handleMiCuentaClose} id="pop-mi-cuenta" >
            {!auth.isAuthenticated &&
              <List component="nav" aria-label="secondary mailbox folders">
                <ListItemLink href="/Login" onClick={handleLoginDialogOpen}>
                  <ListItemText primary="Ingresar" className={classes.ingresar} />
                </ListItemLink>
              </List>
            }
            {auth.isAuthenticated &&
              <List component="nav" aria-label="secondary mailbox folders">

                <ListItemLink href="/Logout" onClick={handleLogoutClick}>
                  <ListItemIcon>
                    <SignOutAltIcon primaryColor={pColor} secondaryColor={sColor} width="20" height="20" />
                  </ListItemIcon>                  
                  <ListItemText primary="Salir"  />
                </ListItemLink>

              </List>
            }

          </Popover>
          <LoginDialog handleClose={handleLoginDialogClose} open={showLoginDialog} />
        </div>
    );
}

export default Page;