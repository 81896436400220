import { List, ListItemText } from '@material-ui/core';
import React, { useState } from 'react';
import { ProveedorDialog } from '..';
import { ListItemLink } from '../../helpers';
import useStyles from './style';

const Page = ({proveedores}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(null);

  const handleCloseDialog = () => {
    setSelected(null);
  }

  const handleProveedorClick = (event, proveedor) => {
    event.preventDefault();
    setSelected(proveedor);
  }

  const handleUpdateProveedor = (proveedor) => {
    setSelected(proveedor);
  }

  return (
    <div className={classes.root}>
      {proveedores.map(proveedor => (
        <List component="nav" aria-label="secondary">
          <ListItemLink href="/Proveedores" onClick={(event)=>handleProveedorClick(event, proveedor)}>
            <ListItemText primary={proveedor.nombre} secondary={proveedor.rubro}/>
          </ListItemLink>
        </List>              
      ))}
      <ProveedorDialog handleUpdateProveedor={handleUpdateProveedor} handleClose={handleCloseDialog} proveedor={selected} open={selected!==null}/>
    </div>
  );
};

export default Page;