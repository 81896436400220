import React from 'react';
import { Dialog, useTheme, Avatar, Card, CardContent, CardHeader, List, ListItem, ListItemIcon, ListItemText, Button, Typography, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import useStyles from './style';
import { CalendarEditIcon, EnvelopeOpenTextIcon, FileIcon, IdCardIcon, MapMarkerIcon, PhoneIcon, TrashIcon, TruckLoadingIcon, WhatsappIcon } from '../../helpers/icons';


const Page = ({open, handleClose, proveedor, onFileChange, onFileUpload, onFileCancel, handleFileClick, handleRemoveFileClick, selectedFile, isFetching}) => {
  const classes = useStyles();
  const theme = useTheme();
  const pColor = theme.palette.primary.main;
  const sColor = theme.palette.secondary.main;

  return (
      <Dialog onClose={handleClose} open={open}>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {proveedor.nombre[0]}
              </Avatar>
            }
            title={proveedor.nombre}
            subheader={proveedor.rubro}
          />
          <CardContent>
            <Typography variant="h5" component="h2">
              Archivos adjuntos
            </Typography>
            {proveedor.archivos.length === 0 && 
              <Typography variant="body2" component="p">
                No hay archivos
              </Typography>
            }
            {proveedor.archivos.length > 0 && 
              <List className={classes.archivos}>
                {proveedor.archivos.map((archivo) => 
                  <ListItem button key={archivo.id} onClick={(event)=>handleFileClick(event, archivo)}>
                    <ListItemText
                      primary={archivo.nombre}
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={(event)=>handleRemoveFileClick(event, archivo)}>
                        <TrashIcon primaryColor={pColor} secondaryColor={sColor} width={20} height={20} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            }

          </CardContent>

          {!selectedFile &&
            <CardContent>
              <Typography variant="h5" component="h2">
                Subir nuevo archivo
              </Typography>
              <div> 
                <input type="file" name="imageUpload" id="proveedoresFileUpload" style={{display: "none"}} onChange={onFileChange} /> 
                <Button  color="secondary" variant="contained" disabled={selectedFile !== null}><label for="proveedoresFileUpload" >Selecciona el archivo</label></Button>
              </div> 
            </CardContent>
          }
          {selectedFile &&
            <CardContent>
              <FileData {...{selectedFile}}/> 
              <Button  color="secondary" variant="contained" onClick={onFileUpload} disabled={isFetching}> 
                Confirmar
              </Button> {' '}
              <Button  color="default" variant="text" onClick={onFileCancel} disabled={isFetching}> 
                Cancelar
              </Button> 
            </CardContent>          
          }

        </Card>
      </Dialog>
  );
};



const FileData = ({selectedFile}) => { 
      
  return ( 
    <>
      {selectedFile && 

        <div> 
          <Typography variant="h5" component="h2">Datos del archivo</Typography>
          <Typography variant="body2" component="p">Nombre: {selectedFile.name}</Typography>
          <Typography variant="body2" component="p">Tipo: {selectedFile.type}</Typography>
          <Typography variant="body2" component="p">Fecha de modificación: {selectedFile.lastModifiedDate && selectedFile.lastModifiedDate.toDateString()}</Typography>
        </div> 
      }
      {!selectedFile && 
        <Typography variant="body2" component="p">Seleccioná el archivo y apretá guardar<br/>para subirlo a este proveedor.</Typography>

      }
    </>
  ); 
} 

  

export default Page;