import React from 'react';
import useStyles from '../style';
import { SwitchGiz, TextGiz, ContentWrapper} from '../../../helpers';
import { Button, Grid } from '@material-ui/core';

const Page = ({formik, title, subTitle, handleChange, comprobantes}) => {

  const classes = useStyles();

  return (
    
    <div className={classes.root}>

      <form autoComplete="off"  onSubmit={formik.handleSubmit} className={classes.root}>
        <ContentWrapper title={title} subTitle={subTitle} actions={
          <>
            <Button variant="contained" color="primary" type="submit"  disabled={formik.isSubmitting}>
              Guardar
            </Button>
          </>
        }>
          <Grid container>
            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="usuario"
                label="Usuario"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el usuario del vendedor"
              />
            </Grid>
            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="password"
                label="Password"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el password del vendedor"
              />
            </Grid>
            <Grid md="12" className={classes.formControl}>
              <SwitchGiz
                title="Está activo?"
                name="activo"
                label="Está activo?"
                formik={formik}
                onChange={handleChange}
                placeholder="El vendedor de encuentra activo??"                  
              />
            </Grid>
          </Grid>

        </ContentWrapper>
      </form>

    </div>
  );
};

export default Page;