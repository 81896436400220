
import { mediosPagoConstants as constants } from "../constants";

const defaultState = {
  isFetching: false, 
  list: [],
  item: {
    id: 0,
    nombre: '',
    activo: true, 
  },
};

export const reducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case constants.INDEX.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.INDEX.SUCCESS:
      return {
        ...state,
        list: payload,
        isFetching: false, 
      };      
    case constants.INDEX.FAILURE:
      return {
        ...state,
        list: [],
        isFetching: false, 
      };  
    case constants.GET_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.GET_ITEM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.GET_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.ADD_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.ADD_ITEM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.ADD_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.ADD_FORM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.ADD_FORM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.ADD_FORM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.EDIT_FORM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.EDIT_FORM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.EDIT_FORM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
      case constants.REMOVE_ITEM.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.REMOVE_ITEM.SUCCESS:
        return {
          ...state,
          list: state.list.filter(x => x.id !== payload),
          isFetching: false, 
        };      
      case constants.REMOVE_ITEM.FAILURE:
        return {
          ...state,
          isFetching: false, 
        };  
            
    default:
      return state;
  }
}