import { useApiProveedores as api} from '../../services/ApiService';
import { proveedoresConstants as constants } from '../constants';
import { baseAction } from '../utils';

const index = () => (dispatch, getState) => {
  return baseAction({
    fn: api().index,
    ...constants.INDEX,
    dispatch,
    getState
  });
}

const getItem = (id) => (dispatch, getState) => {
  return baseAction({
    fn: () => api().getItem(id),
    ...constants.GET_ITEM,
    dispatch,
    getState
  });
}

const addForm = () => (dispatch, getState) => {
  return baseAction({
    fn: api().addForm,
    ...constants.ADD_FORM,
    dispatch,
    getState
  });
}

const editForm = (id) => (dispatch, getState) => {
  return baseAction({
    fn: () => api().editForm(id),
    ...constants.EDIT_FORM,
    dispatch,
    getState
  });
}

const addItem = (data) => (dispatch, getState) => {
  return baseAction({
    fn: () => api().addItem(data),
    ...constants.ADD_ITEM,
    dispatch,
    getState
  });
}

const editItem = (id, data) => (dispatch, getState) => {
  return baseAction({
    fn: () => api().editItem(id, data),
    ...constants.EDIT_ITEM,
    dispatch,
    getState
  });
}

const removeItem = (id) => (dispatch, getState) => {
  return baseAction({
    fn: () => api().removeItem(id),
    ...constants.REMOVE_ITEM,
    dispatch,
    getState
  });
}

const pedidos = () => (dispatch, getState) => {
  return baseAction({
    fn: () => api().pedidos(),
    ...constants.PEDIDOS,
    dispatch,
    getState
  });
}

const entregas = () => (dispatch, getState) => {
  return baseAction({
    fn: () => api().entregas(),
    ...constants.ENTREGAS,
    dispatch,
    getState
  });
}

const uploadFile = (id, data) => (dispatch, getState) => {
  return baseAction({
    fn: () => api().uploadFile(id, data),
    ...constants.UPLOAD_FILE,
    dispatch,
    getState
  });
}

const removeFile = (id) => (dispatch, getState) => {
  return baseAction({
    fn: () => api().removeFile(id),
    ...constants.REMOVE_FILE,
    dispatch,
    getState
  });
}

const selectFile = (proveedor) => (dispatch, getState) => {
  return baseAction({
    fn: () => new Promise((resolve) => {
        resolve({data: proveedor, from: "proveedores.actions"});
      }),
    ...constants.SELECT_FILE,
    dispatch,
    getState
  });
}

export const actions = {
  index,
  getItem,
  addItem,
  editItem,
  addForm,
  editForm,
  removeItem,
  pedidos,
  entregas,
  uploadFile,
  removeFile,
  selectFile,
};