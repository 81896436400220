import React, { useEffect } from 'react';
import Page from './page';
import * as yup from 'yup';
import { ventasActions } from '../../../redux/actions';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { useSnackContext } from '../../../context/Snack';
import { useHistory, useParams } from 'react-router';
import moment from "moment";

export const validationSchema = yup.object({
  id: yup
    .number(),
  fecha: yup
    .date()
    .required('La fecha es requerida'),
  detalle: yup
    .string().nullable()
    .max(255, 'Puede ingresar hasta 255 caracteres'),
  importe: yup
    .number()
    .required('El importe es requerido'),
  idVendedor: yup
    .number()
    .required('El vendedor es requerido'),
  idMedioPago: yup
    .number()
    .required('El medio de pago es requerido'),
});

export const _Index = ({userId, editForm, addForm, editItem, addItem, item, vendedores, mediosPago, title, subTitle, handleControlChange}) => {
  let { id } = useParams()
  let history = useHistory();
  const { openAxiosError, openSuccess } = useSnackContext();

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    const fn = id ? () => editForm(id) : () => addForm();
    formik.setSubmitting(true);
    fn()
    .then(response => {
      formik.setSubmitting(false);
      if(response.item){
        formik.setValues(response.item);
      }else if(!id){
        formik.setValues({
          ...item,
          fecha: moment(),
          idVendedor: userId
        });
      }
    })
    .catch(error => {
      formik.setSubmitting(false);
      openAxiosError(error);
    })
  }
   
  const formik = useFormik({
    initialValues: {
      ...item,
      id: id ? id : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.setSubmitting(true);
      const fn = id ? () => editItem(id, values) : () => addItem(values);
      console.log(fn);
      console.log(values);
      fn()
      .then( response => {
        const msg = id ? "El item se editó correctamente." : "El item se creó correctamente.";
        openSuccess(
          msg, 
          ()=>{ 
            history.replace("/Dashboard/Ventas");
          }
        )
      })
      .catch( error => {
        formik.setSubmitting(false);
        openAxiosError(error);
      })
    },
  });

  const handleChange = (event) => {
    handleControlChange && handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
    formik.setFieldValue( event.target.name, event.target.value );

    console.log(formik)
  }

  return (
    <Page {...{formik, title, subTitle, handleChange, vendedores, mediosPago}}/>
  );
}

const mapStateToProps = state => ({
  item: state.ventas.item,
  vendedores: state.ventas.vendedores,
  mediosPago: state.ventas.mediosPago,
  userId: state.auth.userId,
});

const mapDispatchToProps = {
  addForm: ventasActions.addForm,
  editForm: ventasActions.editForm,
  addItem: ventasActions.addItem,
  editItem: ventasActions.editItem,
}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
