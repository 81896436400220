import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    minWidth: '400px',
  },
  archivos: {
    overflowY: "scroll",
    height: 250
  }
}));

export default useStyles;