import React from 'react';
import useStyles from './style';
import { FormMedioPago } from '../../components';


const Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormMedioPago title="Editar Medio de Pago" subTitle="complete el formulario para editar el medio de pago, confirme las modificaciones con el botón guardar"/>
    </div>
  );
};

export default Page;