import React, { useEffect } from 'react';
import Page from './page';
import * as yup from 'yup';
import { proveedoresActions } from '../../../redux/actions';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { useSnackContext } from '../../../context/Snack';
import { useHistory, useParams } from 'react-router';
import { dias } from '../../../redux/utils';

export const validationSchema = yup.object({
  id: yup
    .number(),
  nombre: yup
    .string('Ingrese el nombre del proveedor')
    .required('El nombre es requerido')
    .max(45, 'Puede ingresar hasta 45 caracteres'),
  rubro: yup
    .string('Ingrese el rubro del proveedor')
    .max(45, 'Puede ingresar hasta 45 caracteres'),
  contacto: yup
    .string('Ingrese el contacto del proveedor')
    .max(45, 'Puede ingresar hasta 45 caracteres'),
  telefono: yup
    .string('Ingrese el teléfono del proveedor')
    .max(45, 'Puede ingresar hasta 45 caracteres'),
  celular: yup
    .string('Ingrese el celular del proveedor')
    .max(45, 'Puede ingresar hasta 45 caracteres'),
  email: yup
    .string('Ingrese el contacto del proveedor')
    .email('Ingrese un correo electrónico válido')
    .max(255, 'Puede ingresar hasta 255 caracteres'),
  direccion: yup
    .string('Ingrese la dirección del proveedor')
    .max(255, 'Puede ingresar hasta 255 caracteres'),
  pedidos: yup
    .array(),
  entregas: yup
    .array(),
  comprobante: yup
    .number('Ingrese el tipo de comprobante que emite el proveedor')
    .required('El dato es requerido') ,
  observaciones: yup
    .string('Ingrese observaciones para el proveedor')
    .max(255, 'Puede ingresar hasta 255 caracteres'),
  activo: yup
    .bool(),
});

export const _Index = ({editForm, addForm, editItem, addItem, item, comprobantes, title, subTitle, handleControlChange}) => {
  let { id } = useParams()
  let history = useHistory();
  const { openAxiosError, openSuccess } = useSnackContext();

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    const fn = id ? () => editForm(id) : () => addForm();
    formik.setSubmitting(true);
    fn()
    .then(response => {
      formik.setSubmitting(false);
      response.item && formik.setValues({
        ...response.item,
        entregas: response.item.entregas.split("-").filter(x => x !== ''),
        pedidos: response.item.pedidos.split("-").filter(x => x !== ''),
      });
    })
    .catch(error => {
      formik.setSubmitting(false);
      openAxiosError(error);
    })
  }
   
  const formik = useFormik({
    initialValues: {
      ...item,
      entregas: [],
      pedidos: [],
      id: id ? id : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.setSubmitting(true);
      const values2 = {
        ...values,
        entregas: values.entregas.sort().join("-"),
        pedidos: values.pedidos.sort().join("-"),
      };
      const fn = id ? () => editItem(id, values2) : () => addItem(values2);
      fn()
      .then( response => {
        const msg = id ? "El item se editó correctamente." : "El item se creó correctamente.";
        openSuccess(
          msg, 
          ()=>{ 
            history.replace("/Dashboard/Proveedores");
          }
        )
      })
      .catch( error => {
        formik.setSubmitting(false);
        openAxiosError(error);
      })
    },
  });

  const handleChange = (event) => {
    handleControlChange && handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
    formik.setFieldValue( event.target.name, event.target.value );
    console.log(formik.values);
  }

  return (
    <Page {...{formik, title, subTitle, handleChange, item, comprobantes}}/>
  );
}

const mapStateToProps = state => ({
  item: state.proveedores.item,
  comprobantes: state.proveedores.comprobantes,
});

const mapDispatchToProps = {
  addForm: proveedoresActions.addForm,
  editForm: proveedoresActions.editForm,
  addItem: proveedoresActions.addItem,
  editItem: proveedoresActions.editItem,
}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
