import React from "react";
import BaseIcon from "./_BaseIcon";

export const Icon = ({ className, primaryColor, secondaryColor, width = "60", height = "60" }) => {
  return (
    <BaseIcon 
        className={className}
        width={width}
        height={height}
        viewBox="0 0 512 512"
    >
  
      <path fill={secondaryColor} d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276a12 12 0 0 1-12 12h-92v92a12 12 0 0 1-12 12h-56a12 12 0 0 1-12-12v-92h-92a12 12 0 0 1-12-12v-56a12 12 0 0 1 12-12h92v-92a12 12 0 0 1 12-12h56a12 12 0 0 1 12 12v92h92a12 12 0 0 1 12 12z"></path>
      <path fill={primaryColor} d="M400 284a12 12 0 0 1-12 12h-92v92a12 12 0 0 1-12 12h-56a12 12 0 0 1-12-12v-92h-92a12 12 0 0 1-12-12v-56a12 12 0 0 1 12-12h92v-92a12 12 0 0 1 12-12h56a12 12 0 0 1 12 12v92h92a12 12 0 0 1 12 12z"></path>
    </BaseIcon>
  );
}