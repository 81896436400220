import React from "react"
import { TextField } from "@material-ui/core"

export const TextGiz = ({name, label, formik, placeholder, onChange, type}) => {

  return (
    <TextField
      fullWidth
      type={type}
      variant="outlined"
      id={name}
      name={name}
      label={label}
      style={{ margin: 8 }}
      placeholder={placeholder}
      value={formik.values[name]}
      onChange={onChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
    />
  )
}

