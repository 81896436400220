import React from 'react';
import Page from './page';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  ProductosIndex, ProductosEdit, ProductosAdd, 
  ProveedoresIndex, ProveedoresEdit, ProveedoresAdd, 
  VentasIndex, VentasEdit, VentasAdd, 
  VendedoresIndex, VendedoresEdit, VendedoresAdd, 
  MediosPagoIndex, MediosPagoEdit, MediosPagoAdd, ReporteVentasDiarias, 
} from '../../pages';
import { ArrowLeftIcon, EditIcon, PlusCircleIcon, TrashIcon } from '../../helpers/icons';
import { Button, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from './style';
import { useTheme } from '@material-ui/core';
import Swal from 'sweetalert2';
import { useSnackContext } from '../../context/Snack';
import { useHistory } from 'react-router';
import { authActions } from '../../redux/actions';

const _Dashboard = ({isAdmin, auth, showMenu}) => {

  const classes = useStyles();
  const theme = useTheme();
  const pColor = theme.palette.primary.main;
  const sColor = theme.palette.secondary.main;
  const { openSuccess, openAxiosError } = useSnackContext();
  let history = useHistory();

  const pages = {
    Productos : {
      component: (props) => <ProductosIndex {...props}/>,
      title: (params) => "Productos",
      itemName: "Producto",
      addURL: (params) => "/Dashboard/ProductosAdd",
      editURL: (params) => `/Dashboard/ProductosEdit/${params.row.id}`,
      deleteRedirect: "/Dashboard/Productos",
    },
    Ventas : {
      component: (props) => <VentasIndex {...props}/>,
      title: (params) => "Ventas",
      itemName: "Venta",
      addURL: (params) => "/Dashboard/VentasAdd",
      editURL: (params) => `/Dashboard/VentasEdit/${params.row.id}`,
      deleteRedirect: "/Dashboard/Ventas",
    },
    Proveedores : {
      component: (props) => <ProveedoresIndex {...props}/>,
      title: (params) => "Proveedores",
      itemName: "Proveedor",
      addURL: (params) => "/Dashboard/ProveedoresAdd",
      editURL: (params) => `/Dashboard/ProveedoresEdit/${params.row.id}`,
      deleteRedirect: "/Dashboard/Proveedores",
    },
    Vendedores : {
      component: (props) => <VendedoresIndex {...props}/>,
      title: (params) => "Vendedores",
      itemName: "Vendedor",
      addURL: (params) => "/Dashboard/VendedoresAdd",
      editURL: (params) => `/Dashboard/VendedoresEdit/${params.row.id}`,
      deleteRedirect: "/Dashboard/Vendedores",
    },   
    MediosPago : {
      component: (props) => <MediosPagoIndex {...props}/>,
      title: (params) => "Medios de Pago",
      itemName: "Medio de Pago",
      addURL: (params) => "/Dashboard/MediosPagoAdd",
      editURL: (params) => `/Dashboard/MediosPagoEdit/${params.row.id}`,
      deleteRedirect: "/Dashboard/MediosPago",
    },
    ProductosEdit : {
      component: (props) => <ProductosEdit {...props}/>,
      title: (params) => "Productos",
      backURL: (params) => "/Dashboard/Productos",
    },
    ProductosAdd : {
      component: (props) => <ProductosAdd {...props}/>,
      title: (params) => "Productos",
      backURL: (params) => "/Dashboard/Productos",
    },
    ProveedoresEdit : {
      component: (props) => <ProveedoresEdit {...props}/>,
      title: (params) => "Proveedores",
      backURL: (params) => "/Dashboard/Proveedores",
    },
    ProveedoresAdd : {
      component: (props) => <ProveedoresAdd {...props}/>,
      title: (params) => "Proveedores",
      backURL: (params) => "/Dashboard/Proveedores",
    },
    VentasEdit : {
      component: (props) => <VentasEdit {...props}/>,
      title: (params) => "Ventas",
      backURL: (params) => "/Dashboard/Ventas",
    },
    VentasAdd : {
      component: (props) => <VentasAdd {...props}/>,
      title: (params) => "Ventas",
      backURL: (params) => "/Dashboard/Ventas",
    },
    VendedoresEdit : {
      component: (props) => <VendedoresEdit {...props}/>,
      title: (params) => "Vendedores",
      backURL: (params) => "/Dashboard/Vendedores",
    },
    VendedoresAdd : {
      component: (props) => <VendedoresAdd {...props}/>,
      title: (params) => "Vendedores",
      backURL: (params) => "/Dashboard/Vendedores",
    },
    MediosPagoEdit : {
      component: (props) => <MediosPagoEdit {...props}/>,
      title: (params) => "Medios de Pago",
      backURL: (params) => "/Dashboard/MediosPago",
    },
    MediosPagoAdd : {
      component: (props) => <MediosPagoAdd {...props}/>,
      title: (params) => "Medios de Pago",
      backURL: (params) => "/Dashboard/MediosPago",
    },
    ReporteVentasDiarias : {
      component: (props) => <ReporteVentasDiarias {...props}/>,
      title: (params) => "Reporte de Ventas Diarias",
    },
  }

  const handleDeleteClick = ({event, itemName, details, fn, deteleRedirect}) => {
    event.preventDefault();
    Swal.fire({
      icon: 'question',
      title: `Eliminar ${itemName}`,
      html: `Está seguro de eliminar este item?<br/> <span>${details}</span>`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cerrar',
      }).then((result) => {
        if (result.isConfirmed) {
          fn()
          .then((response) => {
            openSuccess('El item se eliminó correctamente.');

            //openSuccess('El item se eliminó correctamente.', () => history.replace(deteleRedirect));
          })
          .catch((error) => {
            openAxiosError(error)
          });
        }
      })
  

  }

  const pageParam = useParams().page;
  
  const {component, title, itemName, editURL, deleteRedirect, addURL, backURL} = pages[pageParam] ? pages[pageParam] : pages.Productos;

  const iconBack = (params) => {
    return (
      !backURL ? backURL : 
      <Button>
        <Link align="center" className={classes.iconEdit} component={RouterLink} to={backURL(params)} > 
          <ArrowLeftIcon primaryColor={pColor} secondaryColor={sColor} width={20} height={20} /> Volver
        </Link>
      </Button> 
    )           
  }

  const iconAdd = (params) => {
    return (
      !addURL ? addURL : 
      <Button>
        <Link align="center" className={classes.iconEdit} component={RouterLink} to={addURL(params)} > 
          <PlusCircleIcon primaryColor={pColor} secondaryColor={sColor} width={20} height={20} /> Agregar
        </Link>
      </Button> 
    )
  }

  const iconEdit = (params) => {
    return (
      !editURL ? editURL : 
      <Link align="center" className={classes.iconEdit} component={RouterLink} to={editURL(params)} > 
        <EditIcon primaryColor={pColor} secondaryColor={sColor} width={24} height={24} /> 
      </Link>
    )           
  }

  const iconDelete = (params, details, fn) => {
    return (
      !deleteRedirect ? undefined : 
      <Link align="center" className={classes.iconEdit} component={RouterLink} to={deleteRedirect} onClick={(event) => handleDeleteClick({event, deleteRedirect, itemName, details: details(), fn})} > 
        <TrashIcon primaryColor={pColor} secondaryColor={sColor} width={24} height={24} /> 
      </Link>
    )           
  }

  return (
    <Page {...{title, iconAdd, iconBack, isAdmin: isAdmin(), isAuthenticated: auth.isAuthenticated, showMenu}} >
      {component({iconEdit, iconDelete})}
    </Page>
  );

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    showMenu: state.appBar.showMenu,
  };
}

const mapDispatchToProps = {
  isAdmin: authActions.isAdmin,
}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Dashboard);