import React from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';

export const DataGridIndex = (props) => {
  return (
    <DataGrid         
      {...{
        autoHeight: true,
        rowHeight:60,
        checkboxSelection: false,
        pageSize: 8,
        ...props,
        components: {
          Toolbar: (x) => <GridToolbar  {...props}/>,
          ...props.components, 
        }
      }}
    />
  );
}
