import React from "react"
import { DatePicker as Dtpicker } from "@material-ui/pickers";

export const DatePicker = ({name, label = "Fecha", formik, placeholder, onChange, minDate, invalidDateMessage, minDateMessage, maxDateMessage}) => {

  return (
    <Dtpicker
      InputAdornmentProps={{ position: "start" }}
      value={formik.values[name]}
      placeholder={placeholder}
      onChange={(value)=>{ 
        onChange( {target: { name, value}} )
      }}
      minDate={minDate}
      format="DD/MM/YYYY"
      autoOk={true}
      invalidDateMessage={invalidDateMessage}
      minDateMessage={minDateMessage}
      maxDateMessage={maxDateMessage}
      inputVariant = "outlined" 
      style={{ margin: 8, width: "100%" }}
      invalidLabel={formik.touched[name] && Boolean(formik.errors[name])}
      label={label}
    />
  )
}

