import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import { CssBaseline } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#e7af7a',
    },
    secondary: {
      main: '#028090',
    },
  },
  status: {
    danger: orange[500],
  },
});

const ApplicationThemeProvider = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default ApplicationThemeProvider;