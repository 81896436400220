import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Page from './page';
import {authActions, appBarActions, proveedoresActions} from "../../redux/actions";

import { useHistory } from 'react-router-dom';

const _AppBar = (props) => {

  useEffect(()=>{
    props.loadPedidos();
    props.loadEntregas();
  }, [])

  let history = useHistory();

  const handleMiCuentaOpen = (event) => {
    props.openMiCuenta(event.currentTarget);
  };

  const handleMiCuentaClose = () => {
    props.closeMiCuenta();
  };

  const handlePedidosOpen = (event) => {
    props.loadPedidos()
    props.openPedidos(event.currentTarget);
  };

  const handlePedidosClose = () => {
    props.closePedidos();
  };

  const handleEntregasOpen = (event) => {
    props.loadEntregas()
    props.openEntregas(event.currentTarget);
  };

  const handleEntregasClose = () => {
    props.closeEntregas();
  };

  const handleLoginDialogOpen = (event) => {
    event.preventDefault();
    props.closeMiCuenta();
    props.openLoginDialog();
  }

  const handleLoginDialogClose = () => {
    props.closeLoginDialog();
  }
  
  const handleLogoutClick = (event) => {
    event.preventDefault();
    props.closeMiCuenta();
    props.logout();
    history.replace("/");
  }

  const handleToggleMenu = () => {
    props.toggleMenu();
  }

  return (
      <Page {...props} {...{handleMiCuentaClose, handleMiCuentaOpen, handleLoginDialogOpen, handleLoginDialogClose, handleLogoutClick, handlePedidosOpen, handlePedidosClose, handleEntregasOpen, handleEntregasClose, handleToggleMenu}}/>
  );
};

const mapStateToProps = state => ({
    auth: state.auth,
    ...state.appBar
});

const mapDispatchToProps = {
  logout: authActions.logout,
  openLoginDialog: appBarActions.openLoginDialog,
  closeLoginDialog: appBarActions.closeLoginDialog,
  openMiCuenta: appBarActions.openMiCuenta,
  closeMiCuenta: appBarActions.closeMiCuenta,
  openPedidos: appBarActions.openPedidos, 
  closePedidos: appBarActions.closePedidos, 
  openEntregas: appBarActions.openEntregas, 
  closeEntregas: appBarActions.closeEntregas,
  loadPedidos: proveedoresActions.pedidos,
  loadEntregas: proveedoresActions.entregas,
  toggleMenu: appBarActions.toggleMenu,
}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_AppBar);
