import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader, IconButton, Paper } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme)=>({
  card: {
    minWidth: 200,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    margin: theme.spacing(1),
    backgroundColor: "rgba(255,255,255,0.9)",
    width: "100%",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));


export const ContentWrapper = ({children, actions, title, subTitle, headerActions}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>

      <CardHeader
        action={headerActions && 
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={title}
        subheader={subTitle}
      />
      <CardContent>
        {children}
      </CardContent>
      {actions &&
        <CardActions>
          {actions}
        </CardActions>
      }
    </Card>
  )

}