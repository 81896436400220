import React, { useEffect } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { ventasActions, authActions } from '../../redux/actions';

import moment from "moment";

const _Index = (props) => {

  const currencyFormatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

  const price = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'font-tabular-nums',
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'fecha', headerName: 'Fecha', width: 130, type: 'date' },
    { field: 'detalle', headerName: 'Detalle', flex: 1 },
    { field: 'importe', headerName: 'Importe',  width: 130, ...price },
    { field: 'medioPago.nombre', headerName: 'Medio de Pago',  width: 180, renderCell: (params) => params.row.medioPago.nombre },
    { field: 'vendedor.usuario', headerName: 'Vendedor',  width: 180, renderCell: (params) => params.row.vendedor.usuario },
    { field: 'acciones', headerName: 'Acciones', width: 130, renderCell: (params) => {
      return (<> <> {props.iconEdit(params)} </> <> {props.iconDelete(params, () => params.row.nombre, () => props.removeItem(params.row.id) )} </> </>)
    }},
  ];

  useEffect(()=>{
    const index = props.isAdmin() ? props.index : props.basicIndex;
    index();
  }, []);

  const rows = [
    ...props.list.map(x => ({...x, fecha: moment(x.fecha).toDate()})),
  ]

  return <Page columns={columns} rows={rows} />;
};

const mapStateToProps = state => ({
  list: state.ventas.list,
});

const mapDispatchToProps = {
  index: ventasActions.index,
  isAdmin: authActions.isAdmin,
  basicIndex: ventasActions.basicIndex,
};

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);

export default Index;