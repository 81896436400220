import React, { useEffect, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { proveedoresActions } from '../../redux/actions';
import { Link, useTheme } from '@material-ui/core';
import { FilePdfIcon, TrashIcon } from '../../helpers/icons';
import { Link as RouterLink } from 'react-router-dom';

const _Index = ({index, iconEdit, iconDelete, removeItem, list, isFetching, selectFile}) => {

  const theme = useTheme();
  const pColor = theme.palette.primary.main;
  const sColor = theme.palette.secondary.main;

  useEffect(()=>{
    index();
  }, [])

  const handleArchivosClose = () => {
    selectFile(null);
  }

  const handleArchivosProveedorClick = (event, proveedor) => {
    event.preventDefault();
    selectFile(proveedor);
  }

  const columns =  [
    { field: 'nombre', headerName: 'Nombre', width: 200 },
    { field: 'rubro', headerName: 'Rubro', width: 180 },
    { field: 'contacto', headerName: 'Contacto', width: 120},
    { field: 'telefono', headerName: 'Teléfono', width: 120 },
    { field: 'celular', headerName: 'Celular', width: 120 },
    { field: 'email', headerName: 'Email', width: 300, hide: true},
    { field: 'direccion', headerName: 'Dirección', width: 300, hide: true },
    { field: 'pedidos', headerName: 'Pedidos', flex: 1 },
    { field: 'entregas', headerName: 'Entregas', flex: 1 },
    { field: 'comprobanteStr', headerName: 'Comprobante', width: 130 },
    { field: 'observaciones', headerName: 'Observaciones', width: 300, hide: true },
    { field: 'activo', headerName: 'Activo', width: 100, renderCell: (params) => params.row.activo ? "SI" : "NO" },
    { field: 'acciones', headerName: 'Acciones', width: 100, renderCell: (params) => {
      return (
        <> 
          <Link align="center" component={RouterLink} to="/Dashboard/Proveedores" onClick={(event) => handleArchivosProveedorClick(event, params.row)} > 
            <FilePdfIcon primaryColor={pColor} secondaryColor={sColor} width={24} height={24} /> 
          </Link>
          <> {iconEdit(params)} </> 
          <> {iconDelete(params, () => params.row.nombre, () => removeItem(params.row.id) )} </> 
        </>
      )
    }}
  ];

  return <Page {...{columns, rows: list, isFetching, handleArchivosClose}}  />;
};

const mapStateToProps = state => ({
  list: state.proveedores.list,
  isFetching: state.proveedores.isFetching,
});

const mapDispatchToProps = {
  index: proveedoresActions.index,
  removeItem: proveedoresActions.removeItem,
  selectFile: proveedoresActions.selectFile,
};

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
