import React from 'react';
import useStyles from './style';
import { FormMedioPago } from '../../components'

const Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormMedioPago title="Nuevo Medio de Pago" subTitle="complete el formulario para crear un nuevo medio de pago"/>
    </div>
  );
};

export default Page;