import React, { useEffect, useState } from 'react';
import Page from './page';

export const Index = ({onChange}) => {

  const[showScanInput, setShowScanInput] = useState(false);
  const[scanInput, setScanInput] = useState('');
  const[timer, setTimer] = useState(0);

  useEffect(()=>{
      clearTimeout(timer);
      setTimer(setTimeout((onChange) => {
        onChange(scanInput, () => setShowScanInput(false));
      }, 1000, onChange));
  },[scanInput])

  const handleFabClick = () => {
    setScanInput('');
    setShowScanInput(!showScanInput);
  }

  const handleChangeScanInput = (event) => {
    setScanInput(event.target.value);
  }

  return (
      <Page {...{showScanInput, scanInput, handleFabClick, handleChangeScanInput}}/>
  );
}
