
import { proveedoresConstants as constants } from "../constants";

const defaultState = {
  isFetching: false, 
  list: [],
  pedidos: [],
  entregas: [],
  comprobantes: [],
  selectedFiles: null,
  item: {
    id: 0,
    nombre: '',
    rubro: '',
    contacto: '',
    telefono: '',
    celular: '',
    email: '',
    direccion: '',
    pedidos: '',
    entregas: '',
    comprobante: '',
    observaciones: '',
    activo: true,
  },
};

export const reducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case constants.INDEX.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.INDEX.SUCCESS:
      return {
        ...state,
        list: payload,
        isFetching: false, 
      };      
    case constants.INDEX.FAILURE:
      return {
        ...state,
        list: [],
        isFetching: false, 
      };  
    case constants.GET_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.GET_ITEM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.GET_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.ADD_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.ADD_ITEM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.ADD_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.ADD_FORM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.ADD_FORM.SUCCESS:
      return {
        ...state,
        comprobantes: payload.comprobantes,
        isFetching: false, 
      };      
    case constants.ADD_FORM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.EDIT_FORM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.EDIT_FORM.SUCCESS:
      return {
        ...state,
        comprobantes: payload.comprobantes,
        isFetching: false, 
      };      
    case constants.EDIT_FORM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
      case constants.REMOVE_ITEM.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.REMOVE_ITEM.SUCCESS:
        return {
          ...state,
          list: state.list.filter(x => x.id !== payload),
          isFetching: false, 
        };      
      case constants.REMOVE_ITEM.FAILURE:
        return {
          ...state,
          isFetching: false, 
        };  
      case constants.PEDIDOS.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.PEDIDOS.SUCCESS:
        return {
          ...state,
          pedidos: payload,
          isFetching: false, 
        };      
      case constants.PEDIDOS.FAILURE:
        return {
          ...state,
          isFetching: false, 
        };  
      case constants.ENTREGAS.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.ENTREGAS.SUCCESS:
        return {
          ...state,
          entregas: payload,
          isFetching: false, 
        };      
      case constants.ENTREGAS.FAILURE:
        return {
          ...state,
          isFetching: false, 
        };  
      case constants.UPLOAD_FILE.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.UPLOAD_FILE.SUCCESS:
        return {
          ...state,
          list: state.list.map(x => x.id == payload.id ? payload : x),
          selectedFiles: payload,
          isFetching: false, 
        };      
      case constants.UPLOAD_FILE.FAILURE:
        return {
          ...state,
          isFetching: false, 
        };  
      case constants.REMOVE_FILE.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.REMOVE_FILE.SUCCESS:
        return {
          ...state,
          selectedFiles: payload,
          list: state.list.map(x => x.id == payload.id ? payload : x),
          isFetching: false, 
        };      
      case constants.REMOVE_FILE.FAILURE:
        return {
          ...state,
          isFetching: false, 
        }; 
      case constants.SELECT_FILE.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.SELECT_FILE.SUCCESS:
        return {
          ...state,
          selectedFiles: payload,
          isFetching: false, 
        };      
      case constants.SELECT_FILE.FAILURE:
        return {
          ...state,
          isFetching: false, 
        }; 

    default:
      return state;
  }
}