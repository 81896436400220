export const constants = {
  INDEX: {
    REQUEST: 'VENDEDORES_INDEX_REQUEST',
    SUCCESS: 'VENDEDORES_INDEX_SUCCESS',
    FAILURE: 'VENDEDORES_INDEX_FAILURE',
  },
  GET_ITEM: {
    REQUEST: 'VENDEDORES_GET_ITEM_REQUEST',
    SUCCESS: 'VENDEDORES_GET_ITEM_SUCCESS',
    FAILURE: 'VENDEDORES_GET_ITEM_FAILURE',
  },
  ADD_ITEM: {
    REQUEST: 'VENDEDORES_ADD_ITEM_REQUEST',
    SUCCESS: 'VENDEDORES_ADD_ITEM_SUCCESS',
    FAILURE: 'VENDEDORES_ADD_ITEM_FAILURE',
  },
  EDIT_ITEM: {
    REQUEST: 'VENDEDORES_EDIT_ITEM_REQUEST',
    SUCCESS: 'VENDEDORES_EDIT_ITEM_SUCCESS',
    FAILURE: 'VENDEDORES_EDIT_ITEM_FAILURE',
  },
  ADD_FORM: {
    REQUEST: 'VENDEDORES_ADD_FORM_REQUEST',
    SUCCESS: 'VENDEDORES_ADD_FORM_SUCCESS',
    FAILURE: 'VENDEDORES_ADD_FORM_FAILURE',
  },
  EDIT_FORM: {
    REQUEST: 'VENDEDORES_EDIT_FORM_REQUEST',
    SUCCESS: 'VENDEDORES_EDIT_FORM_SUCCESS',
    FAILURE: 'VENDEDORES_EDIT_FORM_FAILURE',
  },
  REMOVE_ITEM: {
    REQUEST: 'VENDEDORES_REMOVE_ITEM_REQUEST',
    SUCCESS: 'VENDEDORES_REMOVE_ITEM_SUCCESS',
    FAILURE: 'VENDEDORES_REMOVE_ITEM_FAILURE',
  }
};
