import React from 'react';
import { connect } from 'react-redux';
import { proveedoresActions } from '../../redux/actions';
import Page from './page';

export const _Index = ({open, handleClose, proveedor, selectFile, loadPedidos, loadEntregas, handleUpdateProveedor}) => {
  const handleArchivosClose = () => {
    selectFile(null);
  }

  const handleArchivosOpen = () => {
    selectFile(proveedor);
  }

  const handleUpdate = (proveedor) => {
    loadPedidos();
    loadEntregas();
    handleUpdateProveedor(proveedor);
  }

  return (
    <>
      {open && <Page {...{open, handleClose, proveedor, handleArchivosClose, handleArchivosOpen, handleUpdate}}/>}
    </>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  selectFile: proveedoresActions.selectFile,
  loadPedidos: proveedoresActions.pedidos,
  loadEntregas: proveedoresActions.entregas,
};

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
