import React from "react";
import BaseIcon from "./_BaseIcon";

export const Icon = ({ className, primaryColor, secondaryColor, width = "60", height = "60" }) => {
  return (
    <BaseIcon 
        className={className}
        width={width}
        height={height}
        viewBox="0 0 448 512" 
      >
      <path fill={secondaryColor} d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm274.43 163.57l-95 95L131.2 456a10.17 10.17 0 0 1-11.2-9 9.93 9.93 0 0 1 0-2.18l5.4-48.2 95-95a5.07 5.07 0 0 1 7.17 0l46.8 46.8a5.07 5.07 0 0 1 .06 7.15zm55.67-55.67l-26 26a5.07 5.07 0 0 1-7.17 0l-46.8-46.8a5.07 5.07 0 0 1 0-7.17l26-26a20.22 20.22 0 0 1 28.6 0l25.4 25.4a20.22 20.22 0 0 1-.03 28.57zM112 128h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z" class="fa-secondary"></path>
      <path fill={primaryColor} d="M330.1 271.3l-25.4-25.4a20.22 20.22 0 0 0-28.6 0l-26 26a5.07 5.07 0 0 0 0 7.17l46.8 46.8a5.07 5.07 0 0 0 7.17 0l26-26a20.22 20.22 0 0 0 0-28.6zm-55.7 77.1l-46.8-46.8a5.07 5.07 0 0 0-7.17 0l-95 95-5.43 48.2a10.17 10.17 0 0 0 9 11.2 9.93 9.93 0 0 0 2.18 0l48.2-5.4 95-95a5.07 5.07 0 0 0 0-7.17zM400 64h-48v48a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16V64H160v48a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16V64H48a48 48 0 0 0-48 48v80h448v-80a48 48 0 0 0-48-48z"></path>
    </BaseIcon>
  );
}