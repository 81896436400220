import { appBarConstants } from "../constants";

const defaultState = {
  anchorMiCuenta: null,
  anchorEntregas: null,
  anchorPedidos: null,
  showLoginDialog: false,
  showMenu: true,
};

export const reducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case appBarConstants.OPEN_LOGIN_DIALOG:
      return {
        ...state,
        showLoginDialog: true,
      };
    case appBarConstants.CLOSE_LOGIN_DIALOG:
      return {
        ...state,
        showLoginDialog: false,
      };
    case appBarConstants.OPEN_MI_CUENTA:
      return {
        ...state,
        anchorMiCuenta: payload,
      };
    case appBarConstants.CLOSE_MI_CUENTA:
      return {
        ...state,
        anchorMiCuenta: null,
      };
    case appBarConstants.OPEN_ENTREGAS:
      return {
        ...state,
        anchorEntregas: payload,
      };
    case appBarConstants.CLOSE_ENTREGAS:
      return {
        ...state,
        anchorEntregas: null,
      };
    case appBarConstants.OPEN_PEDIDOS:
      return {
        ...state,
        anchorPedidos: payload,
      };
    case appBarConstants.CLOSE_PEDIDOS:
      return {
        ...state,
        anchorPedidos: null,
      };
    case appBarConstants.TOGGLE_MENU:
      return {
        ...state,
        showMenu: !state.showMenu,
      };
    default:
      return state;
  }
}
