import React from 'react';
import useStyles from './style';
import { FormVenta } from '../../components'

const Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormVenta title="Nueva Venta" subTitle="complete el formulario para crear una nueva venta"/>
    </div>
  );
};

export default Page;