import { useApiAuth as api} from '../../services/ApiService';
import { authConstants as constants } from '../constants';
import { toDispatch } from '../utils';

const saveToken = (token) => {
  localStorage.setItem("token", token);
}

export const loadToken = () => {
  return localStorage.getItem("token");
}

const removeToken = () => {
  localStorage.removeItem("token");
}

const load = () => (dispatch, getState) => {
  const token = loadToken();
  if(token){
    api().reloadToken(token)
    .then( response => {
      dispatch(toDispatch(constants.RELOAD_SUCCESS, response));
    })
    .catch( error => {
      dispatch(toDispatch(constants.RELOAD_FAILURE, error));
    })
  } else {
    dispatch(toDispatch(constants.RELOAD_EMPTY_TOKEN));
  }
}


const login = ({usuario, password}, onRequest, onSuccess, onError) => (dispatch, getState) => {
  onRequest && onRequest();
  dispatch(toDispatch(constants.LOGIN_REQUEST));
  api().login(usuario, password)
    .then( response => {
      onSuccess && onSuccess(response);
      saveToken(response);
      dispatch(toDispatch(constants.LOGIN_SUCCESS, response));
    })
    .catch( error => {
      onError && onError(error);
      dispatch(toDispatch(constants.LOGIN_FAILURE, error));
    }) 
}

const logout = () => (dispatch, getState) => {
  console.log("logout");
  removeToken();
  dispatch(toDispatch(constants.LOGOUT));
}

const isAdmin = () => (dispatch, getState) => {
  return getState().auth.role === "Admin";
}

export const actions = {
  load,
  login,
  logout,
  loadToken,
  isAdmin,
};