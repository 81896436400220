import React, { useEffect, useState } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { productosActions } from '../../redux/actions';

const _Index = (props) => {

  useEffect(()=>{
    props.index();
  }, [])

    
  const currencyFormatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

  const price = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'font-tabular-nums',
  };

  
  const columns =  [
    { field: 'nombre', headerName: 'Nombre', width: 250 },
    { field: 'descripcion', headerName: 'Descripcion', flex: 1 },
    { field: 'precioCosto', headerName: 'Costo', type: 'number', width: 120, ...price },
    { field: 'precioMinorista', headerName: 'Minorista', type: 'number', width: 120, ...price },
    { field: 'precioMayorista', headerName: 'Mayorista', type: 'number', width: 120, ...price },
    { field: 'codigo', headerName: 'Codigo', width: 150 },
    { field: 'idProducto', headerName: 'ID Producto', width: 150 },
    { field: 'acciones', headerName: 'Acciones', width: 100, renderCell: (params) => {
      return (<> <> {props.iconEdit(params)} </> <> {props.iconDelete(params, () => params.row.nombre, () => props.removeItem(params.row.id) )} </> </>)
    }}
  ];

  return <Page {...{columns, rows: props.list, isFetching: props.isFetching}}  />;
};

const mapStateToProps = state => ({
  list: state.productos.list,
  isFetching: state.productos.isFetching
});

const mapDispatchToProps = {
  index: productosActions.index,
  removeItem: productosActions.removeItem
};

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
