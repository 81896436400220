
export const constants = {
  OPEN_MI_CUENTA: 'APPBAR_OPEN_MI_CUENTA',
  CLOSE_MI_CUENTA:  'APPBAR_CLOSE_MI_CUENTA',
  OPEN_LOGIN_DIALOG: 'APPBAR_OPEN_LOGIN_DIALOG',
  CLOSE_LOGIN_DIALOG: 'APPBAR_CLOSE_LOGIN_DIALOG',
  OPEN_ENTREGAS: 'APPBAR_OPEN_ENTREGAS',
  CLOSE_ENTREGAS: 'APPBAR_CLOSE_ENTREGAS',
  OPEN_PEDIDOS: 'APPBAR_OPEN_PEDIDOS',
  CLOSE_PEDIDOS: 'APPBAR_CLOSE_PEDIDOS',
  TOGGLE_MENU: 'APPBAR_TOGGLE_MENU',
};