import { Routes } from "./components";
import { SnackProvider } from './context/Snack';
import ApplicationThemeProvider from './_core/ApplicationThemeProvider';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import 'moment/locale/es-mx';
import moment from "moment";
import './helpers/extensions/string.extensions.js';



function App() {

  return (
    <Provider store={store}>
      <BrowserRouter basename="./">
        <ApplicationThemeProvider>
          <SnackProvider>
            <MuiPickersUtilsProvider utils={MomentUtils} >
              <Routes/>
            </MuiPickersUtilsProvider>
          </SnackProvider>
        </ApplicationThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
