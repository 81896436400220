import React from 'react';
import useStyles from './style';
import { useTheme, Fab } from '@material-ui/core';
import { ScannerIcon } from '../../helpers/icons';

const Page = ({showScanInput, scanInput, handleFabClick, handleChangeScanInput}) => {
  const classes = useStyles();
  const theme = useTheme();
  const pColor = theme.palette.secondary.main;
  const sColor = theme.palette.primary.main;
  
  return (
    <>
      <Fab color={showScanInput ? "primary" : "secondary"} aria-label="edit" className={classes.fab} onClick={handleFabClick} >
        <ScannerIcon width="30px" primaryColor="#000000" secondaryColor={showScanInput ? pColor : sColor}/>
      </Fab>
      {showScanInput && <input autoFocus className={classes.fabScan} value={scanInput} onChange={handleChangeScanInput}/>}
    </>
  );
};

export default Page;