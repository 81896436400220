import React, { useContext, useState } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const useSnack = () => {
  
  const [state, setSnackState] = useState({
    open: false, 
    text: "", 
    vertical: 'top', 
    horizontal: 'center', 
    onClose: () => {},
    duration: 5000
  });

  const open = ({text, type, duration = 5000, onClose = ()=>{}}) =>{
    setSnackState({...state, open: true, text: text, onClose: onClose ? onClose : null, duration: duration, type: type})
  }
  
  const openError = (text, onClose = ()=>{}) =>{
    setSnackState({...state, open: true, text: text, onClose: onClose ? onClose : null, duration: 3000, type: "error"})
  }

  const openAxiosError = (error, formik, onClose = ()=>{}) =>{

    if(formik && error && error.response && error.response.data && error.response.data.errors){
      formik.setErrors(error.response.data.errors);
    }

    if(error && error.response && error.response.data && error.response.data.title){
      openError(error.response.data.title, onClose)
      return;
    }

    //console.log("error");
    //console.log(error.response);
    if(!error || !error.request || !error.request.status) {
      openError("Error!.", onClose)
      return;
    }
    //console.log("error2");

    if(error.request.status >= 500){
      openError("Error de servidor.", onClose);
      return;
    }
    //console.log("error3");

    switch(error.request.status){
      case 400: {
        openError("Error de validación de datos.", onClose);
        break;
      }
      case 401: {
        openError("Debe loguearse nuevamente para completar la operación.", onClose)
        break;
      }    
      case 403: {
        openError("No está autorizado para acceder a este contenido.", onClose)
        break;
      }    
      case 404: {
        openError("Esta información no se ha encontrado o ya no se encuentra disponible.", onClose)
        break;
      }    
      case 408: {
        openError("El servidor tardó demasiado en responder.", onClose)
        break;
      }   
      case 410: {
        openError("Este contenido fue eliminado.", onClose)
        break;
      }   
      case 429: {
        openError("Demasiadas peticiones simultaneas.", onClose)
        break;
      } 
      default: {
        openError(`Error de consulta no especificado. (${error.response.status})` , onClose)
      }
    }
  }

  const openSuccess = (text, onClose = ()=>{}) =>{
    setSnackState({...state, open: true, text: text, onClose: onClose ? onClose : null, duration: 1000, type: "success"})
  }

  open.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    duration: PropTypes.number,
    onClose: PropTypes.func,
  };

  const handleOnClose = () => {
    state.onClose && state.onClose();
    setSnackState({...state, open: false, text: "", onClose: () => {}})
  }

  return { 
    open,
    openSuccess,
    openError,
    openAxiosError,
    state,
    component:     
      <Snackbar
        anchorOrigin={{ vertical: state.vertical, horizontal: state.horizontal }}
        key={`${state.vertical},${state.horizontal}`}
        open={state.open}
        //onClose={handleOnClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{state.text}</span>}
        autoHideDuration={state.duration}
        onClose={handleOnClose}
      >
        <MySnackbarContentWrapper
          onClose={handleOnClose}
          variant={state.type}
          message={state.text}
        />

      </Snackbar>
  };
}

export const SnackContext = React.createContext(useSnack); 

export const useSnackContext = () => { 
  return useContext(SnackContext); 
};

export const SnackProvider = ({ children }) => {
  const snack = useSnack();
  return (
    <SnackContext.Provider value={snack}>
      {snack.component}
      {children}
    </SnackContext.Provider>
  );
};


