import { toDispatch } from '../utils';
import { appBarConstants as constants } from '../constants';

const openLoginDialog = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.OPEN_LOGIN_DIALOG));
}

const closeLoginDialog = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.CLOSE_LOGIN_DIALOG));
}

const openMiCuenta = (anchor) => (dispatch, getState) => {
  dispatch(toDispatch(constants.OPEN_MI_CUENTA, anchor));
}

const closeMiCuenta = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.CLOSE_MI_CUENTA));
}

const openPedidos = (anchor) => (dispatch, getState) => {
  dispatch(toDispatch(constants.OPEN_PEDIDOS, anchor));
}

const closePedidos = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.CLOSE_PEDIDOS));
}

const openEntregas = (anchor) => (dispatch, getState) => {
  dispatch(toDispatch(constants.OPEN_ENTREGAS, anchor));
}

const closeEntregas = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.CLOSE_ENTREGAS));
}

const toggleMenu = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.TOGGLE_MENU));
}

export const actions = {
  openLoginDialog,
  closeLoginDialog,
  openMiCuenta,
  closeMiCuenta,
  openPedidos,
  closePedidos,
  openEntregas,
  closeEntregas,
  toggleMenu,
};