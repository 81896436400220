import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height:600,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(8),
  },
  fabScan: {
    position: "fixed",
    bottom: theme.spacing(20),
    right: theme.spacing(8),
  },
}));

export default useStyles;