import React from 'react';
import useStyles from '../style';
import { SelectGiz, TextGiz, ContentWrapper, SwitchGiz } from '../../../helpers';
import { Button, Grid } from '@material-ui/core';
import { dias } from '../../../redux/utils';

const Page = ({formik, title, subTitle, handleChange, comprobantes}) => {

  const classes = useStyles();

  return (
    
    <div className={classes.root}>

      <form autoComplete="off"  onSubmit={formik.handleSubmit} className={classes.root}>
        <ContentWrapper title={title} subTitle={subTitle} actions={
          <>
            <Button variant="contained" color="primary" type="submit"  disabled={formik.isSubmitting}>
              Guardar
            </Button>
          </>
        }>
          <Grid container>
            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="nombre"
                label="Nombre"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese la identificación del proveedor"
              />
            </Grid>
            <Grid md="3" className={classes.formControl}>
              <TextGiz
                name="rubro"
                label="Rubro"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el rubro del proveedor"
              />
            </Grid>
            <Grid md="3" className={classes.formControl}>
              <SwitchGiz
                title="Está activo?"
                name="activo"
                label="Está activo?"
                formik={formik}
                onChange={handleChange}
                placeholder="El vendedor de encuentra activo??"                  
              />
            </Grid>
            <Grid md="4" className={classes.formControl}>
              <TextGiz
                name="contacto"
                label="Contacto"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el contacto del proveedor"
              />
            </Grid>


            <Grid md="4" className={classes.formControl}>
              <TextGiz
                name="telefono"
                label="Teléfono"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el teléfono del proveedor"
              />
            </Grid>

            <Grid md="4" className={classes.formControl}>
              <TextGiz
                name="celular"
                label="Celular"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el celular del proveedor"
              />
            </Grid>


            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="email"
                label="Email"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el email del proveedor"
              />
            </Grid>

            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="direccion"
                label="Dirección"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese la dirección del proveedor"
              />
            </Grid>


            <Grid md="4" className={classes.formControl}>
              <SelectGiz
                items={Object.values(dias)}
                renderValue={(selected) => selected.sort().join("-")}
                name="pedidos"
                label="Pedidos"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese los días de pedidos del proveedor"
                multiple
              />
            </Grid>

            <Grid md="4" className={classes.formControl}>
              <SelectGiz
                items={Object.values(dias)}
                renderValue={(selected) => selected.sort().join("-")}
                name="entregas"
                label="Entregas"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese los días de entrega del proveedor"
                multiple
              />
            </Grid>

            <Grid md="4" className={classes.formControl}>
              <SelectGiz
                items={comprobantes}
                name="comprobante"
                label="Comprobante"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el comprobante que entrega el proveedor"
              />
            </Grid>


            <Grid md="12" className={classes.formControl}>
              <TextGiz
                name="observaciones"
                label="Observaciones"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese observaciones para el proveedor"
              />
            </Grid>

          </Grid>

        </ContentWrapper>
      </form>

    </div>
  );
};

export default Page;