export const constants = {
  INDEX: {
    REQUEST: 'PROVEEDORES_INDEX_REQUEST',
    SUCCESS: 'PROVEEDORES_INDEX_SUCCESS',
    FAILURE: 'PROVEEDORES_INDEX_FAILURE',
  },
  GET_ITEM: {
    REQUEST: 'PROVEEDORES_GET_ITEM_REQUEST',
    SUCCESS: 'PROVEEDORES_GET_ITEM_SUCCESS',
    FAILURE: 'PROVEEDORES_GET_ITEM_FAILURE',
  },
  ADD_ITEM: {
    REQUEST: 'PROVEEDORES_ADD_ITEM_REQUEST',
    SUCCESS: 'PROVEEDORES_ADD_ITEM_SUCCESS',
    FAILURE: 'PROVEEDORES_ADD_ITEM_FAILURE',
  },
  EDIT_ITEM: {
    REQUEST: 'PROVEEDORES_EDIT_ITEM_REQUEST',
    SUCCESS: 'PROVEEDORES_EDIT_ITEM_SUCCESS',
    FAILURE: 'PROVEEDORES_EDIT_ITEM_FAILURE',
  },
  ADD_FORM: {
    REQUEST: 'PROVEEDORES_ADD_FORM_REQUEST',
    SUCCESS: 'PROVEEDORES_ADD_FORM_SUCCESS',
    FAILURE: 'PROVEEDORES_ADD_FORM_FAILURE',
  },
  EDIT_FORM: {
    REQUEST: 'PROVEEDORES_EDIT_FORM_REQUEST',
    SUCCESS: 'PROVEEDORES_EDIT_FORM_SUCCESS',
    FAILURE: 'PROVEEDORES_EDIT_FORM_FAILURE',
  },
  REMOVE_ITEM: {
    REQUEST: 'PROVEEDORES_REMOVE_ITEM_REQUEST',
    SUCCESS: 'PROVEEDORES_REMOVE_ITEM_SUCCESS',
    FAILURE: 'PROVEEDORES_REMOVE_ITEM_FAILURE',
  },
  PEDIDOS: {
    REQUEST: 'PROVEEDORES_PEDIDOS_REQUEST',
    SUCCESS: 'PROVEEDORES_PEDIDOS_SUCCESS',
    FAILURE: 'PROVEEDORES_PEDIDOS_FAILURE',
  },
  ENTREGAS: {
    REQUEST: 'PROVEEDORES_ENTREGAS_REQUEST',
    SUCCESS: 'PROVEEDORES_ENTREGAS_SUCCESS',
    FAILURE: 'PROVEEDORES_ENTREGAS_FAILURE',
  },
  UPLOAD_FILE: {
    REQUEST: 'PROVEEDORES_UPLOAD_FILE_REQUEST',
    SUCCESS: 'PROVEEDORES_UPLOAD_FILE_SUCCESS',
    FAILURE: 'PROVEEDORES_UPLOAD_FILE_FAILURE',
  },
  REMOVE_FILE: {
    REQUEST: 'PROVEEDORES_REMOVE_FILE_REQUEST',
    SUCCESS: 'PROVEEDORES_REMOVE_FILE_SUCCESS',
    FAILURE: 'PROVEEDORES_REMOVE_FILE_FAILURE',
  },
  SELECT_FILE: {
    REQUEST: 'PROVEEDORES_SELECT_FILE_REQUEST',
    SUCCESS: 'PROVEEDORES_SELECT_FILE_SUCCESS',
    FAILURE: 'PROVEEDORES_SELECT_FILE_FAILURE',
  },
  
};
