import React, { useState } from 'react';
import useStyles from './style';
import { DataGridIndex } from '../../helpers';
import { Scanner } from '../../components';

const Page = ({ columns, rows, isFetching}) => {
  const classes = useStyles();
  const [scanInput, setScanInput] = useState('');

  const handleScannerChange = (value, hideScanner) => {
    setScanInput(value);
    //hideScanner();
  }


  return (
    <div className={classes.root}>
      <DataGridIndex
        className={classes.gridToolBar}
        rows={rows} 
        columns={columns} 
        loading={isFetching}       
        filterModel={!scanInput ? 
          {items: []}
          : 
          {items: [
            { columnField: 'codigo', operatorValue: 'equals', value: scanInput },
          ]}
        } 
      />
      
      <Scanner onChange={handleScannerChange}/>

   
    </div>

  );
};


export default Page;