import React from "react";
import BaseIcon from "./_BaseIcon";

export const Icon = ({ className, primaryColor, secondaryColor, width = "60", height = "60" }) => {
  return (
    <BaseIcon 
        className={className}
        width={width}
        height={height}
        viewBox="0 0 512 512" 
    >
              
      <path fill={secondaryColor} d="M416 272a32 32 0 1 0 32 32 32 32 0 0 0-32-32zm16-240H64A64 64 0 0 0 0 96a32 32 0 0 0 32 32h48a16 16 0 0 1 0-32h384a16 16 0 0 0 16-16 48 48 0 0 0-48-48z"></path>
      <path fill={primaryColor} d="M461.2 128H32A32 32 0 0 1 0 96v320a64 64 0 0 0 64 64h397.2c28 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"></path>
      
    </BaseIcon>
  );
}

