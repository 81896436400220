import React, { useState } from 'react';
import { connect } from 'react-redux';
import { proveedoresActions } from '../../redux/actions';
import Page from './page';
import { API_URL } from '../../services/ApiService';

const _Index = ({handleClose, proveedor, uploadFile, removeFile, isFetching, handleUpdate }) => {

  const [selectedFile, setSelectedFile] = useState(null);
  const open = proveedor !== null;

  const handleFileClick = (event, archivo) => {
    event.preventDefault();
    window.open(`${API_URL}/archivos/${archivo.id}`, '_blank', 'frame=true,nodeIntegration=no')
  }
  const handleRemoveFileClick = (event, archivo) => {
    event.preventDefault();
    removeFile(archivo.id).then((response) => {
      handleUpdate && handleUpdate(response);
    });
  }

  const onFileCancel = (event) => {
    setSelectedFile(null);
  }
    // On file select (from the pop up) 
  const onFileChange = (event) => { 
    console.log(event);
    // Update the state 
    setSelectedFile( event.target.files[0] ); 
    event.target.value = [ ];
  }; 
     
    // On file upload (click the upload button) 
  const onFileUpload = () => { 
    // Create an object of formData 
    const formData = new FormData(); 
    
    // Update the formData object 
    formData.append( 
      "myFile", 
      selectedFile, 
      selectedFile.name 
    ); 
    
    // Request made to the backend api 
    // Send formData object 
    uploadFile(proveedor.id, formData).then((response) => {
      setSelectedFile(null)
      handleUpdate && handleUpdate(response);     
    }); 
  }; 
    

  return (
    <>
      {open && <Page {...{open, handleClose, proveedor, onFileChange, onFileCancel, onFileUpload, selectedFile, isFetching, handleFileClick, handleRemoveFileClick}}/>}
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    isFetching: state.proveedores.isFetching,
    proveedor: state.proveedores.selectedFiles,
  };
}

const mapDispatchToProps = {
  uploadFile: proveedoresActions.uploadFile,
  removeFile: proveedoresActions.removeFile,
}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
