import React from 'react';
import { connect } from 'react-redux';
import Page from './page';

const _Index = (props) => {

  return (
      <Page {...props}/>
  );

}

const mapStateToProps = state => ({
  proveedores: state.proveedores.pedidos,
});

export const Index = connect(mapStateToProps)(_Index);
