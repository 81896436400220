import React, { useEffect } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { vendedoresActions } from '../../redux/actions';

const _Index = (props) => {

  useEffect(()=>{
    props.index();
  }, [])


  const columns =  [
    { field: 'usuario', headerName: 'Usuario', width: 250 },
    { field: 'password', headerName: 'Password', width: 220 },
    { field: 'activo', headerName: 'Activo', width: 100, renderCell: (params) => params.row.activo ? "SI" : "NO" },
    { field: 'acciones', headerName: 'Acciones', width: 100, renderCell: (params) => {
      return (<> <> {props.iconEdit(params)} </> <> {props.iconDelete(params, () => params.row.nombre, () => props.removeItem(params.row.id) )} </> </>)
    }}
  ];

  return <Page {...{columns, rows: props.list, isFetching: props.isFetching}}  />;
};

const mapStateToProps = state => ({
  list: state.vendedores.list,
  isFetching: state.vendedores.isFetching
});

const mapDispatchToProps = {
  index: vendedoresActions.index,
  removeItem: vendedoresActions.removeItem
};

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
