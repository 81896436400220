import React from 'react';
import useStyles from './style';
import { DataGridIndex } from '../../helpers';
import { ProveedorArchivosDialog } from '../../components';

const Page = ({ columns, rows, handleArchivosClose}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DataGridIndex rows={rows} columns={columns}/>
      <ProveedorArchivosDialog handleClose={handleArchivosClose}/>
    </div>
  );
};

export default Page;