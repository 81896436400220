import React from "react";
import BaseIcon from "./_BaseIcon";

export const Icon = ({ className, primaryColor, secondaryColor, width = "60", height = "60" }) => {
  return (
    <BaseIcon 
        className={className}
        width={width}
        height={height}
        viewBox="0 0 640 512"
    >
      <path fill={secondaryColor} d="M256 448a32 32 0 0 0 32-32V304.27L205 448zM368 64H96a95.91 95.91 0 0 0-1.7 191.79L6.44 408A48 48 0 0 0 24 473.53l55.4 32A48 48 0 0 0 145 488l134-232h89.09a16 16 0 0 0 16-16V80a16 16 0 0 0-16.03-16z"></path>
      <path fill={primaryColor} d="M632 448H456a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zm0-96H456a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm0-64H456a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm0-224H456a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zm0 96H456a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zm0-160H456a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h176a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8z"></path>
    </BaseIcon>
  );
}
