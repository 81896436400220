import React from 'react';
import useStyles from './style';
import { Switch, Route, Redirect } from "react-router-dom";
import { AppBar, Footer, Dashboard, Home, Logout } from "../";
import { Container } from "@material-ui/core";

//const Dashboard = React.lazy(() => import('../Dashboard'));
//const Home = React.lazy(() => import('../Home'));
//const Logout = React.lazy(() => import('../Logout'));


const Page = ({ auth }) => {
  const classes = useStyles();

  return (
    <>
      <AppBar/>
      <Container maxWidth={false} className={classes.container}>
        {auth.loaded && 
        <>
          {!auth.isAuthenticated &&
            <Switch>
              <Route path="/Home" render={() => <Home/>}/>  
              <Route exact path="*" render={() => <Redirect to="/Home"/>} />               
            </Switch>
          }
          {auth.isAuthenticated &&
            <Switch >
              <Route exact path="/Home" render={() => <Home/>} />   
              <Route exact path="/Logout" render={() => <Logout/>} />  
              <Route path="/Dashboard/:page/:id" render={() => <Dashboard/>} />  
              <Route path="/Dashboard/:page" render={() => <Dashboard/>} />  
              <Route exact path="/" render={() => <Home/>} />              
            </Switch>
          }
        </>
        
        }
      </Container>
      <Footer/>
    </>
  );
}

export default Page;


