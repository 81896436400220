import React from "react";

const BaseIcon = ({ className, viewBox, children, width, height }) =>{
  return (
    <svg
        style={{padding: "0px", margin: "0px", marginBottom: "-4px"}}
        className={className}
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );

}

export default BaseIcon;