
import { reportesConstants as constants } from "../constants";

const defaultState = {
  isFetching: false, 
  reporteVentasDiarias: {
    reporte: [],
    totalizado: [],
    total: 0
  },
};

export const reducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case constants.REPORTES_VENTAS_DIARIAS.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.REPORTES_VENTAS_DIARIAS.SUCCESS:
      return {
        ...state,
        reporteVentasDiarias: payload,
        isFetching: false, 
      };      
    case constants.REPORTES_VENTAS_DIARIAS.FAILURE:
      return {
        ...state,
        reporteVentasDiarias: defaultState.reporteVentasDiarias,
        isFetching: false, 
      };  
            
    default:
      return state;
  }
}