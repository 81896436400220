import { useApiReportes as api} from '../../services/ApiService';
import { reportesConstants as constants } from '../constants';
import { baseAction } from '../utils';

const reporteVentasDiarias = (values) => (dispatch, getState) => {
  return baseAction({
    fn: () => api().reporteVentasDiarias(values),
    ...constants.REPORTES_VENTAS_DIARIAS,
    dispatch,
    getState
  });
}

export const actions = {
  reporteVentasDiarias,
};