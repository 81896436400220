import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonGoogle: {
    width: "100%",
    margin: theme.spacing(1),
    backgroundColor: "#c23321",
    color: "white"
  },
  buttonFacebook: {
    width: "100%",
    margin: theme.spacing(1),
    backgroundColor: "#3b5998",
    color: "white"
  },
  buttonIniciarSesion: {
    width: "100%",
    margin: theme.spacing(1),
  },
  forgotPassword: {
    paddingTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default useStyles;