import React from 'react';
import useStyles from '../style';
import { Button, Grid } from '@material-ui/core';
import { TextGiz, ContentWrapper, SwitchGiz } from '../../../helpers';

const Page = ({formik, title, subTitle, handleChange, vendedores}) => {

  const classes = useStyles();

  return (
    
    <div className={classes.root}>

      <form autoComplete="off"  onSubmit={formik.handleSubmit} className={classes.root}>
        <ContentWrapper title={title} subTitle={subTitle} actions={
          <>
            <Button variant="contained" color="primary" type="submit"  disabled={formik.isSubmitting}>
              Guardar
            </Button>
          </>
        }>
          <Grid container>
            
            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="nombre"
                label="Nombre"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el nombre del medio de pago"
              />
            </Grid>
            <Grid md="6" className={classes.formControl}>
              <SwitchGiz
                title="Está activo?"
                name="activo"
                label="Está activo?"
                formik={formik}
                onChange={handleChange}
                placeholder="El medio de pago de encuentra activo?"                  
              />
            </Grid>
          </Grid>

        </ContentWrapper>
      </form>

    </div>
  );
};

export default Page;