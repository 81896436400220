import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { appBarActions, authActions } from '../../redux/actions';

const _Logout = (props) => {
  useEffect(() => {
    props.closeMiCuenta();
    props.logout();
  }, []);

  return (
      <Redirect to="/"/>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  logout: authActions.logout,
  closeMiCuenta: appBarActions.closeMiCuenta,
}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Logout);
