import React from 'react';
import useStyles from './style';
import Grid from '@material-ui/core/Grid';

export const Page = (props) => {
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item  xs={12}>
        </Grid>
        <Grid item  xs={12}>
        </Grid>
        <Grid item xs={12} md={1}>
        </Grid>
        <Grid item xs={12} md={10} style={{ height:"500px" }}>

        </Grid>
        <Grid item xs={12} md={1}>
        </Grid>
      </Grid>
    </div>
  );
}