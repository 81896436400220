import React from 'react';
import { DataGridIndex } from '../../helpers';
import useStyles from './style';

const Page = ({ columns, rows }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DataGridIndex rows={rows} columns={columns}/>
    </div>
  );
};

export default Page;