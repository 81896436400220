import React from 'react';
import useStyles from '../style';
import { Button, Grid, useTheme } from '@material-ui/core';
import { DatePicker, TextGiz, SelectGiz, ContentWrapper } from '../../../helpers';
import { Scanner } from '../..';

const Page = ({formik, title, subTitle, handleChange, vendedores, mediosPago }) => {

  const classes = useStyles();
  
  const handleScannerChange = (text, hideScanner) => {
    if(text){
      const value = `${text.substring(3, 9)}.${text.substring(9, 11)}`;
      handleChange (value.toEventValue("importe"));
      hideScanner();
    }
  }

  return (
    
    <div className={classes.root}>

      <form autoComplete="off"  onSubmit={formik.handleSubmit} className={classes.root}>
        <ContentWrapper title={title} subTitle={subTitle} actions={
          <>
            <Button variant="contained" color="primary" type="submit"  disabled={formik.isSubmitting}>
              Guardar
            </Button>
          </>
        }>
          <Grid container>
            <Grid md="6" className={classes.formControl}>
              <DatePicker
                name= "fecha"
                formik={formik}
                placeholder="Fecha"
                onChange={handleChange}
                invalidDateMessage="La fecha es inválida"
                minDateMessage="La fecha es inválida"
                maxDateMessage="La fecha es inválida"                    
              />
            </Grid>
            <Grid md="6" className={classes.formControl}>
              <SelectGiz
                items={mediosPago}
                name="idMedioPago"
                label="Medio de Pago"
                formik={formik}
                onChange={handleChange}
                labelWidth={120}
                placeholder="Ingrese el medio de pago de la venta"
              />
            </Grid>
            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="importe"
                label="Importe"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el importe de la venta"
              />
            </Grid>

            <Grid md="6" className={classes.formControl}>
              <SelectGiz
                items={vendedores}
                name="idVendedor"
                label="Vendedor"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el vendedor que realizó la venta"
              />
            </Grid>

            <Grid md="12" className={classes.formControl}>
              <TextGiz
                name="detalle"
                label="Detalle"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el detalle de la venta"
              />
            </Grid>
          </Grid>
          <Scanner onChange={handleScannerChange}/>
        </ContentWrapper>
      </form>

    </div>
  );
};

export default Page;