import React, { useEffect } from 'react';
import Page from './page';
import { connect } from 'react-redux';
import { mediosPagoActions } from '../../redux/actions';

const _Index = (props) => {

  useEffect(()=>{
    props.index();
  }, [])


  const columns =  [
    { field: 'nombre', headerName: 'Nombre', width: 250 },
    { field: 'activo', headerName: 'Activo', width: 100, renderCell: (params) => params.row.activo ? "SI" : "NO" },
    { field: 'acciones', headerName: 'Acciones', width: 100, renderCell: (params) => {
      return (<> <> {props.iconEdit(params)} </> <> {props.iconDelete(params, () => params.row.nombre, () => props.removeItem(params.row.id) )} </> </>)
    }}
  ];

  return <Page {...{columns, rows: props.list, isFetching: props.isFetching}}  />;
};

const mapStateToProps = state => ({
  list: state.mediosPago.list,
  isFetching: state.mediosPago.isFetching
});

const mapDispatchToProps = {
  index: mediosPagoActions.index,
  removeItem: mediosPagoActions.removeItem
};

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
