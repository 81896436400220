import React from 'react';
import useStyles from './style';
import { FormVendedor } from '../../components'

const Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormVendedor title="Nuevo Vendedor" subTitle="complete el formulario para crear un nuevo vendedor/a"/>
    </div>
  );
};

export default Page;