import React from 'react';
import useStyles from '../style';
import { TextGiz, ContentWrapper } from '../../../helpers';
import { Button, Grid } from '@material-ui/core';

const Page = ({formik, title, subTitle, handleChange}) => {

  const classes = useStyles();

  return (
    
    <div className={classes.root}>

      <form autoComplete="off"  onSubmit={formik.handleSubmit} className={classes.root}>
        <ContentWrapper title={title} subTitle={subTitle} actions={
          <>
            <Button variant="contained" color="primary" type="submit"  disabled={formik.isSubmitting} >
              Guardar
            </Button>
          </>
        }>
          <Grid container>
            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="nombre"
                label="Nombre"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese la identificación del producto"
              />
            </Grid>
            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="descripcion"
                label="Descripcion"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese la descripción del producto"
              />
            </Grid>

            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="codigo"
                label="Código"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el código de barras del producto"
              />
            </Grid>
            <Grid md="6" className={classes.formControl}>
              <TextGiz
                name="idProducto"
                label="ID Producto"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el ID del producto"
              />
            </Grid>

            <Grid md="4" className={classes.formControl}>
              <TextGiz
                name="precioCosto"
                label="Precio de Costo"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el precio de costo"
              />
            </Grid>
            <Grid md="4" className={classes.formControl}>
              <TextGiz
                name="precioMinorista"
                label="Precio Minorista"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el precio minorista"
              />
            </Grid>
            <Grid md="4" className={classes.formControl}>
              <TextGiz
                name="precioMayorista"
                label="Precio Mayorista"
                formik={formik}
                onChange={handleChange}
                placeholder="Ingrese el precio mayorista"
              />
            </Grid>

          </Grid>

        </ContentWrapper>
      </form>

    </div>
  );
};

export default Page;