import React from 'react';
import useStyles from './style';
import { FormProveedor } from '../../components'

const Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormProveedor title="Nuevo Proveedor" subTitle="complete el formulario para crear un nuevo proveedor"/>
    </div>
  );
};

export default Page;