import { makeStyles } from '@material-ui/core/styles';

import bg1 from "../../assets/img/bg/bg-1.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${bg1})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;