import React, { useEffect } from 'react';
import Page from './page';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect } from "react-redux";
import { authActions, appBarActions } from "../../redux/actions";
import { useSnackContext } from '../../context/Snack';
import { useHistory } from 'react-router';

const _Login = (props) => {
  
  const {openSuccess, openError} = useSnackContext();
  let history = useHistory();

  const validationSchema = yup.object({
    usuario: yup
      .string('Ingrese su usuario o email')
      .required('El usuario o email es requerido')
      .max(255, "Puede ingresar hasta 255 caracteres"),
    password: yup
      .string("Ingrese el password")
      .required("El password es requerido")
      .max(20, "Puede ingresar hasta 20 caracteres"),
    recordar: yup
      .bool()
  });

  useEffect(()=>{
    formik.setSubmitting(props.auth.isFetching);
  },[props.auth.isFetching])

  const handleControlChange = {
  }
  
  const formik = useFormik({
    initialValues: {
      usuario: '',
      password: '',
      recordar: true,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      
      props.login(
        values,
        ()=>{
          formik.setSubmitting(true);
        },
        (response)=>{
          formik.setSubmitting(false);
          props.closeLoginDialog();
          openSuccess(
            "Login correcto", 
            ()=>{ 
              history.replace("/Dashboard/Productos");
            }
          )
        },
        (error)=>{
          formik.setSubmitting(false);
          openError("Error en el login");
        },
      );
      /*
      login( values.usuario, values.password ) 
      .then( response => {
        onAuth (response.data.token) 
        formik.setSubmitting(false);
        openSuccess(
          "Se ha logueado correctamente.", 
          ()=>{ 
            history.replace("/");
          }
        )
      })
      .catch( error => {
        formik.setSubmitting(false);
        openError("Los datos ingresados son incorrectos.");
      })
      */
    },
  });

  return (
      <Page title="Iniciar sesión" subTitle="" {...{formik, handleControlChange}} />
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps = {
  login: authActions.login,
  closeLoginDialog: appBarActions.closeLoginDialog,

}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Login);
