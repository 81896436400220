import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { ContentWrapper } from '../../helpers/ContentWrapper';
import { TextGiz } from '../../helpers/TextGiz';
import { SwitchGiz } from '../../helpers/SwitchGiz';
import useStyles from './style';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const Page = ({title, subTitle, formik, handleControlChange}) => {
  const classes = useStyles();

  const handleChange = (event) => {
      handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
      formik.handleChange(event);
  }

  return (
     
        <ContentWrapper title={title} subTitle={subTitle}>
          <form onSubmit={formik.handleSubmit} >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextGiz
                  name="usuario"
                  label="Usuario"
                  formik={formik}
                  onChange={handleChange}
                  placeholder="Ingrese su nombre de usuario"
                />
              </Grid>
              <Grid item xs={12}>
                <TextGiz
                  type="password"
                  name="password"
                  label="Password"
                  formik={formik}
                  onChange={handleChange}
                  placeholder="Ingrese su password"
                />
              </Grid>
              <Grid item xs={6}>
                <SwitchGiz
                  type="recordar"
                  name="recordar"
                  label="No cerrar sesión"
                  formik={formik}
                  onChange={handleChange}
                  placeholder="No cerrar sesión"
                />
              </Grid>
              <Grid item xs={6}>
              </Grid>
               <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="secondary" 
                  disabled={formik.isSubmitting} 
                  className={classes.buttonIniciarSesion}
                >
                  Iniciar Sesión
                </Button>
                  
                </Grid>
            </Grid>
           
          </form>
          <Backdrop className={classes.backdrop} open={formik.isSubmitting}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </ContentWrapper>

  );
};

export default Page;